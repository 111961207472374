import { ReactComponent as PeopleIcon } from '@assets/icons/people.svg';
import { ReactComponent as ScreenIcon } from '@assets/icons/screen.svg';
import { Box, useDisclosure } from '@chakra-ui/react';
import { ImageCard } from '@features/assessment/assessment-organiser/ImageCard';
import PanelEventInfos from '@features/assessment/PanelEventInfos';
import { api } from '@utils//api';
import { Event } from '@utils/types';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import ChoiceOrganiser from './ChoiceOrganiser';
import InviteParticipants from './InviteParticipants';
import ModalInvitations from './ModalInvitations';
import ParticipantsChoice from './ParticipantsChoice';
import ResultsOrganiser from './ResultsOrganiser';

export default function AssessmentOrganiser() {
  const stepsOrganiser = ['invitation', 'pending', 'assessment'];
  const { id: eventId } = useParams();
  const [event, setEvent] = useState(null);
  const [step, setStep] = useState(stepsOrganiser[0]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const sendInvitations = () => {
    setStep('pending');
    setTimeout(() => {
      setStep('assessment');
    }, 5000);
  };
  useEffect(() => {
    api.get(`/events/`).then((data: any) => {
      const currentEvent = data.events.filter((event: Event) => event.id === eventId)[0];
      setEvent(currentEvent);
    });
  }, []);

  return (
    <Wrapper>
      <SidePanel>
        {event && <PanelEventInfos event={event} />}

        {step === 'assessment' && (
          <Box mt="20px">
            <ChoiceOrganiser />
          </Box>
        )}
      </SidePanel>

      <MainContent>
        {step === 'invitation' && (
          <>
            <ImageCard
              title="Ajoutons quelques participants"
              icon={<PeopleIcon />}
              text="Vous pouvez commencer par inviter les participants à dévoiler nos recommandations et leurs votes"
              buttonText="Inviter des participants"
              onClick={onOpen}
            />

            <ModalInvitations
              isOpen={isOpen}
              onClose={onClose}
              onSend={sendInvitations}
            />
          </>
        )}
        {step === 'pending' && (
          <>
            <ImageCard
              title="En attente des premiers votes"
              icon={<ScreenIcon />}
              text="Notre recommandation sera affichée une fois que les premiers participants auront voté. Encouragez tout le monde à voter pour une réponse plus pertinente."
            />
          </>
        )}
        {step === 'assessment' && (
          <>
            <ResultsOrganiser />

            <ParticipantsChoice />
          </>
        )}
      </MainContent>
      <SidePanel>
        <InviteParticipants />
      </SidePanel>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
`;

const MainContent = styled.div`
  padding: 0 20px;
  flex: 1;
  position: relative;
  margin-bottom: 200px;
`;
const SidePanel = styled.div`
  width: 400px;
  padding: 0 20px;
`;
