import { ReactComponent as EarthIcon } from '@assets/icons/earth.svg';
import { Heading, Text } from '@chakra-ui/react';
import { colors } from '@theme/colors';
import { Criteria } from '@utils/types';
import styled from 'styled-components';

type CardCriteriaProps = {
  criteria: Criteria;
};

export default function CardCriteria({ criteria }: CardCriteriaProps) {
  const { description, label, score, shortDescription, unit, value } = criteria;

  const getColor = (stepNumber: number) => {
    const color = stepNumber <= score ? colors.ratingLevel[score - 1] : colors.grey;
    return color;
  };
  return (
    <Wrapper>
      <Category>
        <EarthIcon />

        <Text fontSize="sm" color="black60" ml="8px">
          {label}
        </Text>
      </Category>

      <Heading size="xl" color="black" _firstLetter={{ textTransform: 'uppercase' }}>
        {value} {unit}
      </Heading>
      <Text fontSize="sm" color="black60" mb="20px">
        {shortDescription}
      </Text>

      {score && (
        <>
          <Rating>
            <Step color={getColor(1)}></Step>
            <Step color={getColor(2)}></Step>
            <Step color={getColor(3)}></Step>
            <Step color={getColor(4)}></Step>
            <Step color={getColor(5)}></Step>
          </Rating>
        </>
      )}

      <Text fontSize="sm" color="black60" mb="20px">
        {description}
      </Text>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  border-radius: 16px;
  background: ${colors.white};
  padding: 16px;
  max-width: 250px;
`;

const Category = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;
const Rating = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  grid-gap: 4px;
  height: 8px;
  margin-bottom: 8px;
`;
const Step = styled.div`
  border-radius: 24px;

  background-color: ${(props) => props.color};
`;
