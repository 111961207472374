import { t } from 'i18next';

export const translateFlow = (flow: any) => {
  const translations = [
    { key: 'traits.lastname', value: t('account.lastname') },
    { key: 'traits.firstname', value: t('account.firstname') },
    { key: 'traits.company', value: t('account.company') },
    {
      key: 'traits.acceptTermsOfUse',
      value: t('account.acceptTermsOfUse'),
    },
    {
      key: 'traits.acceptGDPR',
      value: t('account.acceptGDPR'),
    },
  ];
  const translationMap = Object.fromEntries(
    translations.map(({ key, value }) => [key, value]),
  );

  const flowCopy = { ...flow };

  flowCopy.ui.nodes.forEach((field: any, index: number) => {
    if (Object.prototype.hasOwnProperty.call(translationMap, field.attributes.name)) {
      flowCopy.ui.nodes[index].meta.label.text = translationMap[field.attributes.name];
    }
  });

  return flowCopy;
};
