import { ReactComponent as ArrowIcon } from '@assets/icons/arrow-right-2.svg';
import { ReactComponent as HybridImage } from '@assets/icons/hybrid-illus.svg';
import { ReactComponent as PresentialImage } from '@assets/icons/presential-illus.svg';
import { ReactComponent as RemoteImage } from '@assets/icons/remote-illus.svg';
import { ReactComponent as TickIcon } from '@assets/icons/tick-verified.svg';
import { Box, Heading, Text } from '@chakra-ui/react';
import { colors } from '@theme/colors';
import styled from 'styled-components';

type BannerRecommandationProps = {
  type: string;
  isGreyedOut?: boolean;
  onClick: () => void;
};

export default function BannerRecommandation({
  type,
  isGreyedOut = false,
  onClick,
}: BannerRecommandationProps) {
  let headingText = '';
  let imageComponent = null;

  if (type === 'presential') {
    headingText = 'Présentiel';
    imageComponent = <PresentialImage />;
  } else if (type === 'remote') {
    headingText = 'Distanciel';
    imageComponent = <RemoteImage />;
  } else if (type === 'hybrid') {
    headingText = 'Hybride';
    imageComponent = <HybridImage />;
  }
  const handleClick = () => {
    onClick();
  };
  return (
    <Wrapper isGreyedOut={isGreyedOut} onClick={handleClick}>
      <Box display="flex" alignItems="center">
        <TickIcon />
        <Text ml="8px" fontSize="xs">
          Notre recommandation
        </Text>
      </Box>

      <Heading size="lg" color="black" my="8px">
        {headingText}
      </Heading>

      <Box display="flex" alignItems="center">
        <Text fontSize="sm" color="primary" mr="8px">
          Sur quoi est basée cette recommandation ?
        </Text>
        <ArrowIcon />
      </Box>
      <Image>{imageComponent}</Image>
    </Wrapper>
  );
}

const Wrapper = styled.div<{ isGreyedOut: boolean }>`
  border-radius: 16px;
  background: ${colors.primary50};
  padding: 16px;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  opacity: ${(props) => (props.isGreyedOut ? 0.4 : 1)};
  filter: ${(props) => (props.isGreyedOut ? 'grayscale(0.8)' : 'none')};
`;
const Image = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  height: 100%;
  display: flex;
  align-items: end;
  svg {
    height: 90%;
    width: 200px;
  }
`;
