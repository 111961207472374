import { Box, Input, List, ListItem } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import wretch from 'wretch';

type Address = {
  city: string;
  id: string;
  label: string;
  latitude: number;
  longitude: number;
  name: string;
  postalCode: string;
};
type AutoCompleteLocationProps = {
  onLocationSelected: (address: Address) => void;
  type?: 'address' | 'city';
  defaultSearchText?: string;
};

export default function AutoCompleteLocation({
  onLocationSelected,
  type = 'address',
  defaultSearchText = '',
}: AutoCompleteLocationProps) {
  const [searchText, setSearchText] = useState('');
  const [isSelected, setIsSelected] = useState(false);
  const [hasDefaultSearch, setHasDefaultSearch] = useState(false);
  const [results, setResults] = useState([]);
  const limit = 10;
  const param = type === 'city' ? '&type=municipality' : null;
  const placeholder =
    type === 'city' ? 'Rechercher une ville' : 'Rechercher une adresse en France';

  const selectLocation = (address: Address) => {
    setSearchText(address.label);
    onLocationSelected(address);
    setIsSelected(true);
  };
  const searchLocation = (value: string) => {
    setSearchText(value);
    setIsSelected(false);
  };
  useEffect(() => {
    if (!hasDefaultSearch && searchText.length > 2) {
      wretch(
        `https://api-adresse.data.gouv.fr/search/?q=${encodeURIComponent(
          searchText,
        )}&limit=${limit}${param}`,
      )
        .get()
        .json((data) => {
          const addresses = data.features.map((feature: any) => ({
            name: feature.properties.name,
            city: feature.properties.city,
            postalCode: feature.properties.citycode,
            id: feature.properties.id,
            label: feature.properties.label,
            latitude: feature.geometry.coordinates[1],
            longitude: feature.geometry.coordinates[0],
          }));

          setResults(addresses);
        })
        .catch((error) => {
          console.error("Une erreur s'est produite :", error);
        });
    } else {
      setResults([]);
    }
    setHasDefaultSearch(false);
  }, [searchText]);

  useEffect(() => {
    if (defaultSearchText.length > 0) {
      setSearchText(defaultSearchText);
      setHasDefaultSearch(true);
    }
  }, [defaultSearchText]);

  return (
    <Box>
      <Input
        placeholder={placeholder}
        value={searchText}
        onChange={(e) => searchLocation(e.target.value)}
      />
      {!isSelected && results.length > 0 && (
        <List position="absolute" zIndex="10">
          {results.map((address: any) => (
            <ListItem onClick={() => selectLocation(address)} key={address.id}>
              {address.label}
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
}
