import { Button, Heading, Text } from '@chakra-ui/react';
import { colors } from '@theme/colors';
import { ReactElement } from 'react';
import styled from 'styled-components';

type ImageCardProps = {
  icon: ReactElement;
  title: string;
  text: string;
  buttonText?: string;
  onClick?: () => void;
};

export function ImageCard({ icon, title, text, buttonText, onClick }: ImageCardProps) {
  return (
    <ImageCardContainer>
      {icon}
      <Heading size="md" mt="20px" mb="12px">
        {title}
      </Heading>
      <Text color="black60" fontSize="md" mb="20px" textAlign="center">
        {text}
      </Text>
      {buttonText && onClick && <Button onClick={onClick}>{buttonText}</Button>}
    </ImageCardContainer>
  );
}

const ImageCardContainer = styled.div`
  padding: 40px;
  background-color: ${colors.white};
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
`;
