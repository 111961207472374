export const colors = {
  primary: '#166666',
  primary10: '#f1f7f7',
  primary50: '#cceae9',
  primary100: '#166666',
  primary200: '#185453',

  blue: ' #0094FF',
  blueBackground: '#EAEFFD',
  blueLight: '#f2faff',

  red: ' #BD203D',
  redBackground: ' #F3DEE3',
  redBackgroundLight: ' #fff4f7',

  yellow: ' #E6A615',
  yellowBackground: ' #FDF6E7',

  black20: '#E4E4E4',
  black40: '#9F9F9F',
  black60: '#666666',
  black80: '#444444',

  black: '#04080b',
  white: '#FFFFFF',
  grey: '#F6F6F6',

  ratingLevel: ['#FFD6C9', '#FFE7D1', '#F3EBA4', '#C9F0AF', '#AEE0A4'],
};
