import { useRadio } from '@chakra-ui/react';
import { colors } from '@theme/colors';
import styled from 'styled-components';

type RadioButtonProps = {
  children: React.ReactNode;
  className?: string;
  isDisabled?: boolean;
};

export default function RadioButton(props: RadioButtonProps) {
  const { getInputProps } = useRadio(props);
  const input = getInputProps();

  return (
    <Wrapper
      className={`${input.checked ? 'is-selected' : ''} ${
        props.isDisabled ? 'is-disabled' : ''
      }`}>
      <input {...input} />
      <BoxCheckbox className={props.className}>{props.children}</BoxCheckbox>
    </Wrapper>
  );
}

const Wrapper = styled.label`
  svg {
    margin-right: 8px;
  }
`;
const BoxCheckbox = styled.div`
  background-color: transparent;
  cursor: pointer;
  border-width: 1px;
  border-radius: 36px;
  border-color: #00000040;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  font-weight: bold;

  /* Styles when checked */
  .is-selected & {
    background-color: ${colors.primary50};
    border-color: ${colors.primary};
    color: ${colors.primary};
  }
  .is-disabled & {
    opacity: 0.4;
    filter: grayscale(100%);
    background: ${colors.black20};
  }
  &.opacity10 {
    background-color: rgba(255, 255, 255, 0.1);
    border-width: 2px;
    border-color: ${colors.primary};
    color: ${colors.white};
    font-weight: 400;
    .is-selected & {
      border-color: ${colors.white};
    }
  }
  &.transportMode {
    .is-selected & {
      path {
        fill: ${colors.primary};
      }
    }
  }
`;
