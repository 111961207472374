import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const supportedLanguages = ['fr', 'en'];
const browserLanguage = navigator.language.split('-')[0];

const languageToUse = supportedLanguages.includes(browserLanguage)
  ? browserLanguage
  : 'en';

i18n.use(initReactI18next).init({
  resources: {
    fr: {
      translation: {
        account: {
          firstname: 'Prénom',
          lastname: 'Nom',
          company: 'Société',
          acceptTermsOfUse: "J'accepte les conditions d'utilisation",
          acceptGDPR: "J'accepte le règlement général sur la protection des données",
        },
      },
    },
    en: {
      translation: {
        account: {
          firstname: 'First name',
          lastname: 'Last name',
          company: 'Company',
          acceptTermsOfUse: 'I accept the terms of use',
          acceptGDPR: 'I accept the general data protection regulation',
        },
      },
    },
  },
  lng: languageToUse,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
