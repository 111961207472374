import { ReactComponent as GroupIcon } from '@assets/icons/group-primary.svg';
import { ReactComponent as SoloIcon } from '@assets/icons/solo.svg';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Select,
  Text,
  Textarea,
  useRadioGroup,
} from '@chakra-ui/react';
import Datepicker from '@components/Datepicker';
import FormElement from '@components/FormElement';
import RadioCardEvent from '@components/RadioCardEvent';
import { yupResolver } from '@hookform/resolvers/yup';
import { CalendarDate } from '@uselessdev/datepicker';
import { api } from '@utils/api';
import { times } from '@utils/times';
import { Location } from '@utils/types';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { schema, schemaMeetings } from './schema';
import * as S from './StyledEventCreationForm';
import { getPayload } from './utlis';

type FormValues = {
  name: string;
  motive: string;
  type: string;
  dateFrom: string;
  timeStart: string;
  dateTo: string;
  timeEnd: string;
  location: Location | null;
  locationDeparture: Location | null;
  description: string;
};

export default function EventCreationForm() {
  const [motives, setMotives] = useState<any[]>([]);
  const [organizerDisabled, setOrganizerDisabled] = useState<boolean>(true);
  const [formElements, setFormElements] = useState<any[]>([]);
  const navigate = useNavigate();
  const defaultTime = times[0].label;
  const [schemaValidation, setSchemaValidation] = useState(schema);

  useEffect(() => {
    let typedText = '';
    const targetWord = 'sogdevmode';
    setValue('type', 'traveler');

    const handleKeyDown = (event) => {
      typedText += event.key;
      typedText = typedText.slice(-targetWord.length);
      if (typedText === targetWord) {
        setOrganizerDisabled(false);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const fetchMotivesAndFormElements = async () => {
      try {
        const motivesResponse: any = await api.get(`/motives`);
        const formElementsResponse: any = await api.get(`/assessmentForm`);
        setMotives(motivesResponse?.motives);
        setFormElements(formElementsResponse?.formElements);
      } catch (error) {
        console.error("Une erreur s'est produite :", error);
      }
    };

    fetchMotivesAndFormElements();
  }, []);

  useEffect(() => {
    updateFormElements(formElements);
  }, [formElements]);

  const {
    handleSubmit,
    register,
    setValue,
    control,
    trigger,
    watch,
    formState: { isSubmitted, errors },
  } = useForm({
    defaultValues: {
      name: '',
      type: 'traveler',
      location: null,
      locationDeparture: null,
      dateFrom: '',
      dateTo: '',
      timeStart: defaultTime,
      timeEnd: defaultTime,
      motive: '',
      description: '',
      financialGain: '',
      lastPhysicalMeeting: '',
      meetingsNumber: null,
    },
    resolver: yupResolver(schemaValidation),
  });

  const updateFormElements = (elements: any[]) => {
    elements.map((element: any) => {
      if (element.type === 'select') {
        const defaultValue = element.options[0].value;
        setValue(element.name, defaultValue);
      }
    });

    if (formElements.find((element) => element.name === 'meetingsNumber')) {
      const newSchema = schemaValidation.concat(schemaMeetings);
      setSchemaValidation(newSchema);
    }
  };

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'type',
    defaultValue: 'traveler',
    onChange: (value) => {
      setValue('type', value);
      errors.type && trigger();
    },
  });

  const onSubmit = (values: FormValues) => {
    const payload = getPayload(formElements, values);

    api
      .url(`/events`)
      .post(payload)
      .then((data: any) => {
        const eventId = data?.event?.id;
        const type = data?.event?.type;
        if (type) {
          navigate(`/assessment-${type}/${eventId}`);
        }
      })
      .catch((error) => {
        console.error("Une erreur s'est produite :", error);
      });
  };

  return (
    <S.Wrapper>
      <Heading size="xl" textAlign="center" mb="32px">
        {`Créer un nouvel évènement`}
      </Heading>

      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <S.RowForm>
          <FormElement
            control={control}
            errors={errors}
            register={register}
            element={{ label: "Nom de l'évènement", name: 'name', type: 'text' }}
          />
        </S.RowForm>

        <S.RowForm>
          <S.EventTypeGroup {...getRootProps()}>
            <RadioCardEvent key="solo" {...getRadioProps({ value: 'traveler' })}>
              <SoloIcon />
              <Heading size="sm" margin="16px 0 4px 0">
                Voyageur
              </Heading>
              <Text fontSize="sm">
                {`J'évalue mon déplacement sans inviter de participants`}
              </Text>
            </RadioCardEvent>
            <RadioCardEvent
              key="group"
              {...getRadioProps({ value: 'organizer' })}
              isDisabled={organizerDisabled}>
              <GroupIcon />
              <Heading size="sm" margin="16px 0 4px 0">
                Organisateur
              </Heading>
              <Text fontSize="sm">
                {`J'évalue un évènement que j'organise en invitant des participants`}
              </Text>
            </RadioCardEvent>
          </S.EventTypeGroup>

          {errors.type && <Text variant="error">{errors.type.message} </Text>}
        </S.RowForm>

        <S.RowForm>
          <FormElement
            control={control}
            errors={errors}
            register={register}
            element={{
              label: "Lieu de l'évènement",
              name: 'location',
              type: 'place',
            }}
          />
        </S.RowForm>

        <S.RowForm>
          <S.RowDate>
            <Box>
              <FormLabel>{'Date de début'}</FormLabel>
              <S.GroupDate>
                <Controller
                  name="dateFrom"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <Datepicker
                      invalid={!!errors.dateFrom}
                      onDateSelect={(date: CalendarDate) => {
                        onChange(date);
                        if (isSubmitted) {
                          trigger('dateFrom');
                          trigger('dateTo');
                        }
                      }}
                    />
                  )}
                />

                <FormControl isInvalid={!!errors.timeStart}>
                  <Select
                    id="timeStart"
                    defaultValue={defaultTime}
                    {...register('timeStart')}>
                    {times.map((time, i) => (
                      <option key={i} value={time.label}>
                        {time.label}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </S.GroupDate>
            </Box>

            <S.StyledArrowRightIcon />

            <Box>
              <FormLabel>{'Date de fin'}</FormLabel>
              <S.GroupDate>
                <Controller
                  name="dateTo"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <Datepicker
                      invalid={!!errors.dateTo}
                      onDateSelect={(date: CalendarDate) => {
                        onChange(date);
                        if (isSubmitted) {
                          trigger('dateFrom');
                          trigger('dateTo');
                        }
                      }}
                    />
                  )}
                />
                <FormControl isInvalid={!!errors.timeEnd}>
                  <Select
                    id="timeEnd"
                    defaultValue={defaultTime}
                    {...register('timeEnd')}>
                    {times.map((time, i) => (
                      <option key={i} value={time.label}>
                        {time.label}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </S.GroupDate>
            </Box>
          </S.RowDate>
          {errors.dateFrom && <Text variant="error">{errors.dateFrom.message} </Text>}
          {errors.dateTo && <Text variant="error">{errors.dateTo.message} </Text>}
        </S.RowForm>

        {motives.length > 0 && (
          <S.RowForm>
            <FormElement
              control={control}
              errors={errors}
              register={register}
              element={{
                label: 'Motif',
                placeholder: 'Choisir un motif',
                name: 'motive',
                type: 'select',
                options: motives,
              }}
            />
          </S.RowForm>
        )}

        {watch('type') === 'traveler' && formElements.length > 0 && (
          <>
            {formElements.map((element) => (
              <S.RowForm key={element.name}>
                <FormElement
                  control={control}
                  errors={errors}
                  register={register}
                  element={element}
                />
              </S.RowForm>
            ))}
          </>
        )}

        <S.RowForm>
          <FormLabel>{'Informations'}</FormLabel>
          <Textarea
            {...register('description')}
            placeholder="Entrez les informations ou la description de l'évènement"
          />
        </S.RowForm>

        <Button mt={4} colorScheme="teal" width="100%" type="submit">
          {`Créer un évènement`}
        </Button>
      </S.Form>
    </S.Wrapper>
  );
}
