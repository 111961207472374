import { ReactComponent as StarIcon } from '@assets/icons/star.svg';
import { Box } from '@chakra-ui/react';
import { colors } from '@theme/colors';
import { forwardRef, useState } from 'react';
import styled from 'styled-components';

interface RatingProps {
  size?: number;
  scale?: number;
  fillColor?: string;
  emptyColor?: string;
  defaultValue?: number | null;
  onChange?: (value: number) => void;
  isDisabled?: boolean;
}

const Rating = forwardRef<HTMLInputElement, RatingProps>(
  (
    {
      defaultValue,
      size = 18,
      scale = 5,
      fillColor = colors.yellow,
      emptyColor = colors.black20,
      onChange,
      isDisabled = false,
    },
    ref,
  ) => {
    const [rating, setRating] = useState(defaultValue ?? 0);
    const [hoverIndex, setHoverIndex] = useState(0);
    const buttons = [];

    const onClick = (idx: number) => {
      setRating(idx);
      onChange && onChange(idx);
    };

    const handleMouseEnter = (idx: number) => {
      setHoverIndex(idx);
    };

    const handleMouseLeave = () => {
      setHoverIndex(0);
    };

    const RatingIcon = ({ fill, disabled }: { fill: boolean; disabled: boolean }) => (
      <StyledIcon
        disabled={disabled}
        w={`${size}px`}
        h={`${size}px`}
        color={fill ? fillColor : emptyColor}>
        <StarIcon />
      </StyledIcon>
    );

    const RatingButton = ({ idx, fill }: { idx: number; fill: boolean }) => (
      <Box
        as="button"
        aria-label={`Rate ${idx}`}
        height={`${size}px`}
        width={`${size}px`}
        mx={1}
        onClick={() => !isDisabled && onClick(idx)}
        onMouseEnter={() => !isDisabled && handleMouseEnter(idx)}
        onMouseLeave={handleMouseLeave}
        _focus={{ outline: 0 }}>
        <RatingIcon disabled={isDisabled} fill={fill} />
      </Box>
    );

    for (let i = 1; i <= scale; i++) {
      buttons.push(
        <RatingButton
          key={i}
          idx={i}
          fill={i <= (hoverIndex > 0 ? hoverIndex : rating)}
        />,
      );
    }

    return (
      <Wrapper>
        <input name="rating" type="hidden" value={rating.toString()} ref={ref} />
        {buttons}
      </Wrapper>
    );
  },
);

Rating.displayName = 'Rating';

const StyledIcon = styled.div<{
  w: string;
  h: string;
  color: string;
  disabled: boolean;
}>`
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  svg {
    width: ${({ w }) => w};
    height: ${({ h }) => h};

    * {
      fill: ${({ color }) => color};
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  gap: 4px;
`;

export default Rating;
