export const routes = {
  HOME: '/',
  LOGIN: '/login',
  RECOVERY: '/recovery',
  REGISTRATION: '/registration',
  SETTINGS: '/settings',
  VERIFICATION: '/verification',
  CREATE_EVENT: '/create-event',
  DOCUMENTATION: '/documentation',
  ASSESSMENT_TRAVELER: '/assessment-traveler',
  ASSESSMENT_ORGANIZER: '/assessment-organizer',
};
