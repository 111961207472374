import { api } from '@utils/api';
import { Event } from '@utils/types';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import EventsChoiceCreation from './EventsChoiceCreation';
import EventsList from './EventsList';

export default function EventsUser() {
  const [events, setEvents] = useState<Event[]>([]);

  const handleDeleteEvent = (events: Event[]) => {
    setEvents(events);
  };

  useEffect(() => {
    api
      .get(`/events/`)
      .then((data: any) => {
        setEvents(data?.events);
      })
      .catch((error) => {
        console.error("Une erreur s'est produite :", error);
      });
  }, []);
  return (
    <Wrapper>
      {events.length > 0 ? (
        <EventsList events={events} deleteEvent={handleDeleteEvent} />
      ) : (
        <EventsChoiceCreation />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div``;
