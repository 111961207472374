import { ReactComponent as ArrowRightIcon } from '@assets/icons/arrow-right.svg';
import { colors } from '@theme/colors';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 700px;
  margin: auto;
  padding-top: 32px;
  margin-bottom: 100px;
`;
export const RowForm = styled.div`
  margin: 20px 0;
`;
export const Form = styled.form`
  background: ${colors.white};
  border-radius: 16px;
  padding: 20px;

  ${RowForm} {
    &:first-child {
      margin: 0;
    }
  }
`;

export const EventTypeGroup = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 20px;
  align-items: stretch;
`;
export const GroupDate = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 8px;
`;
export const RowDate = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledArrowRightIcon = styled(ArrowRightIcon)`
  margin: 25px 16px 0 16px;
`;
