import { ReactComponent as EarthImage } from '@assets/icons/earth-points.svg';
import styled from 'styled-components';
type BubbleBackgroundType = {
  children?: React.ReactNode;
};

export function AccountBackground({ children }: BubbleBackgroundType) {
  return (
    <AccountContainer>
      <AccountContent>{children}</AccountContent>
      <BackgroundWrapper>
        <EarthImage />
      </BackgroundWrapper>
    </AccountContainer>
  );
}

const AccountContainer = styled.div`
  position: relative;
  width: 100%;
  min-height: calc(100vh - 200px);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AccountContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: relative;
  padding: 50px 0 100px 0;
  width: 100%;
  max-width: 550px;
  margin: 0 30px;

  .authCard {
    width: 100%;
  }
`;

const BackgroundWrapper = styled.div`
  position: absolute;
  top: 300px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  svg {
    width: 100%;
    height: auto;
    max-width: 6200px;
  }
`;

export default AccountBackground;
