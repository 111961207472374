import { times } from '@utils/times';
import { Location } from '@utils/types';

export const formatDate = (date: string, time: string): string => {
  const { hours, minutes } = times.filter((t) => t.label === time)[0];
  const newDate = new Date(date);
  newDate.setHours(hours);
  newDate.setMinutes(minutes);
  const formattedDate = newDate.toISOString();
  return formattedDate;
};

export const formatLocation = (location: Location) => {
  const { name, city, label, postalCode, latitude, longitude } = location;
  const newLocation = {
    name: name,
    city: city,
    address: label,
    postalCode: postalCode,
    latitude: latitude,
    longitude: longitude,
  };
  return newLocation;
};

export const getPayload = (formElements: any, values: any) => {
  const type = values.type;
  const payload: any = {
    event: {
      name: values.name,
      motive: values.motive,
      type: type,
      dateFrom: formatDate(values.dateFrom, values.timeStart),
      dateTo: formatDate(values.dateTo, values.timeEnd),
      location: values.location && formatLocation(values.location),
      description: values.description,
      assessmentParameters: {},
    },
  };
  if (type === 'traveler') {
    formElements.forEach((element: any) => {
      if (element.type === 'place') {
        payload.event.assessmentParameters[element.name] =
          values.locationDeparture && formatLocation(values.locationDeparture);
      } else {
        const key = element.name as keyof typeof values;
        payload.event.assessmentParameters[key] = values[key];
      }
    });
  }
  return payload;
};
