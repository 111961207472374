import { ReactComponent as CalendarIcon } from '@assets/icons/calendar-black.svg';
import { ReactComponent as EditIcon } from '@assets/icons/edit.svg';
import { ReactComponent as LocationIcon } from '@assets/icons/location.svg';
import { ReactComponent as PhotoIcon } from '@assets/icons/photo-profile.svg';
import Profile3 from '@assets/icons/profile-man1.png';
import Profile4 from '@assets/icons/profile-man2.png';
import Profile1 from '@assets/icons/profile-woman1.png';
import Profile2 from '@assets/icons/profile-woman2.png';
import { ReactComponent as TrashIcon } from '@assets/icons/trash.svg';
import { Box, Heading, IconButton, Text } from '@chakra-ui/react';
import TickIcon from '@components/TickIcon';
import { colors } from '@theme/colors';
import { apiUrl } from '@utils/api';
import { formatDate, formatTime } from '@utils/format';
import { Event } from '@utils/types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import wretch from 'wretch';

type EventCardProps = {
  event: Event;
  deleteEvent: (eventId: string) => void;
};

export default function EventCard({ event, deleteEvent }: EventCardProps) {
  const { name: creatorName } = event.creator;
  const { createdAt, dateFrom, dateTo, motive, name: eventName, location } = event;

  const handleDeleteEvent = async () => {
    await wretch(`${apiUrl}/events/${event.id}`, { credentials: 'include' }).delete();
    deleteEvent(event.id);
  };

  return (
    <Wrapper>
      <HeaderCard>
        <PhotoIcon />
        <HeaderCardCreator>
          <Text fontSize="12px" textTransform="uppercase" color="black60">
            Créé le {formatDate(createdAt)} par
          </Text>
          <Heading size="sm">{creatorName}</Heading>
        </HeaderCardCreator>
      </HeaderCard>
      <ContentCardTitle>
        <Box>
          <Heading color="primary" size="md">
            {eventName}
          </Heading>
          <Text fontSize="sm" color="black80" textTransform="capitalize">
            {motive}
          </Text>
        </Box>
        <Box>
          <Link to={`/assessment-${event.type}/${event.id}`}>
            <IconButton
              colorScheme="teal"
              backgroundColor={colors.primary10}
              _hover={{ background: colors.primary50 }}
              borderRadius="100%"
              aria-label="Editer l'évènement"
              size="lg"
              icon={<EditIcon />}
            />
          </Link>

          <IconButton
            background={colors.primary10}
            _hover={{ background: colors.primary50 }}
            aria-label="Supprimer l'évènement"
            size="lg"
            onClick={() => handleDeleteEvent()}
            icon={<TrashIcon />}
          />
        </Box>
      </ContentCardTitle>
      <ContentCardInfos>
        <EventDetails>
          <Detail>
            <CalendarIcon />
            <Box ml="12px">
              <Text fontSize="sm">{formatDate(dateFrom)}</Text>
              <Text fontSize="sm" color="black40">
                {formatTime(dateFrom)} - {formatTime(dateTo)}
              </Text>
            </Box>
          </Detail>
          <Detail>
            <LocationIcon />
            <Box ml="12px">
              <Text fontSize="sm">{location.address}</Text>
              <Text fontSize="sm" color="black40">
                {location.postalCode}
              </Text>
            </Box>
          </Detail>
        </EventDetails>
        <ParticipantsSection>
          <ParticipantsList>
            <Participant>
              <StyledTickIcon color={colors.primary} />
              <img src={Profile1} alt="" />
            </Participant>
            <Participant>
              <StyledTickIcon color={colors.primary} />
              <img src={Profile2} alt="" />
            </Participant>
            <Participant>
              <StyledTickIcon color={colors.primary} />
              <img src={Profile3} alt="" />
            </Participant>
            <Participant>
              <StyledTickIcon color={colors.primary} />
              <img src={Profile4} alt="" />
            </Participant>
          </ParticipantsList>
          <MoreNumber>+5</MoreNumber>
        </ParticipantsSection>
      </ContentCardInfos>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background: ${colors.primary50};
  border-radius: 16px;
  grid-template-columns: auto auto;
  grid-gap: 20px; /* Espacement de 49px entre les colonnes */
  align-items: stretch;
  margin-bottom: 32px;
`;
const HeaderCard = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 20px;
`;
const HeaderCardCreator = styled.div`
  margin-left: 12px;
`;

const ContentCardTitle = styled.div`
  padding: 16px 20px;
  background: ${colors.white};
  border-radius: 16px 16px 0 0;
  border-bottom: 1px solid ${colors.black20};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ContentCardInfos = styled.div`
  padding: 16px 20px;
  background: ${colors.white};
  border-radius: 0 0 16px 16px;
`;
const EventDetails = styled.div`
  display: flex;
`;
const Detail = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;
const ParticipantsSection = styled.div`
  display: flex;
  margin-top: 16px;
`;
const ParticipantsList = styled.div`
  display: flex;
`;
const Participant = styled.div`
  position: relative;
  margin-right: 4px;
`;
const StyledTickIcon = styled(TickIcon)`
  position: absolute;
  right: 0;
  bottom: 0;
`;

const MoreNumber = styled.div`
  width: 32px;
  height: 32px;
  background: ${colors.grey};
  color: ${colors.black};
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
