import { ReactComponent as SearchIcon } from '@assets/icons/search-input.svg';
import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { Event } from '@utils/types';
import { useEffect, useState } from 'react';

import EventCard from './EventCard';

type EventsListProps = {
  events: Event[];
  deleteEvent: (events: Event[]) => void;
};

export default function EventsList({ events, deleteEvent }: EventsListProps) {
  const [eventsList, setEventsList] = useState<Event[]>([]);

  useEffect(() => {
    setEventsList(events);
  }, [events]);

  const handleDeleteEvent = (eventId: string) => {
    const updatedEvents = events.filter((event: Event) => event.id !== eventId);
    deleteEvent(updatedEvents);
  };
  return (
    <>
      <InputGroup my="32px">
        <InputLeftElement pointerEvents="none" ml="8px" mt="2px">
          <SearchIcon />
        </InputLeftElement>
        <Input
          type="tel"
          variant="search"
          pl="48px"
          placeholder="Rechercher des évènements"
        />
      </InputGroup>

      {eventsList &&
        eventsList.map((event) => (
          <EventCard key={event.id} event={event} deleteEvent={handleDeleteEvent} />
        ))}
    </>
  );
}
