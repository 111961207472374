import { ReactComponent as ArrowUp } from '@assets/icons/arrow-up.svg';
import { ReactComponent as CarIcon } from '@assets/icons/car-white.svg';
import { ReactComponent as PlaneIcon } from '@assets/icons/plane-white.svg';
import { ReactComponent as TrainIcon } from '@assets/icons/train-white.svg';
import {
  Box,
  Button,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import Rating from '@components/Rating';
import { colors } from '@theme/colors';
import { api } from '@utils//api';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import ModalSurvey from './ModalSurvey';

type ChoiceTravelerProps = {
  links: any;
};

export default function ChoiceTraveler({ links }: ChoiceTravelerProps) {
  const [transportChoice, setTransportChoice] = useState<string | null>(null);
  const [isGo, setIsGo] = useState<boolean>(false);
  const [hasChosen, setHasChosen] = useState<boolean>(false);
  const [hasValidatedSurvey, setHasValidatedSurvey] = useState<boolean>(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [initialRating, setInitialRating] = useState<number | null>(null);

  useEffect(() => {}, []);

  const setTransport = (choice: string) => {
    setTransportChoice(choice);
    choice ? setIsGo(true) : setIsGo(false);
  };
  const handleSurvey = (value: number) => {
    setInitialRating(value);
    onOpen();
  };
  const getTransportLabel = (choice: string): string => {
    switch (choice) {
      case 'rail':
        return 'Train';
      case 'car':
        return 'Voiture';
      case 'flight':
        return 'Avion';
      default:
        return '';
    }
  };
  const setStayChoice = () => {
    setIsGo(false);
    setTransportChoice(null);
  };
  const submitChoice = () => {
    const payload = {
      choice: { isGo: isGo, transport: transportChoice },
    };

    api
      .url(links.choice.href)
      .post(payload)

      .catch((error) => {
        console.error("Une erreur s'est produite :", error);
      })
      .finally(() => {
        setHasChosen(true);
      });
  };

  return (
    <Wrapper>
      <Description>
        <Heading size="md" color="white" mb="4px">
          Stay or Go ?
        </Heading>
        <Text fontSize="sm" color="white">
          Compte tenu de ces informations, que pensez-vous faire ?
        </Text>
      </Description>
      {!hasChosen ? (
        <Choice>
          <Button
            variant="opacity10"
            onClick={() => setStayChoice()}
            border={isGo ? '' : '2px solid white'}>
            Stay
          </Button>

          <Menu>
            <MenuButton
              as={Button}
              mx="16px"
              variant="opacity10"
              border={transportChoice ? '2px solid white' : ''}
              rightIcon={<ArrowUp />}>
              <Box display="flex">
                <Box mr="8px">
                  Go avec {transportChoice ? getTransportLabel(transportChoice) : '...'}
                </Box>

                {transportChoice === 'rail' && <TrainIcon />}
                {transportChoice === 'car' ? <CarIcon /> : null}
                {transportChoice === 'flight' ? <PlaneIcon /> : null}
              </Box>
            </MenuButton>
            <MenuList>
              <MenuItem onClick={() => setTransport('rail')}>
                <MenuIcon>
                  <TrainIcon />
                </MenuIcon>
                Train
              </MenuItem>
              <MenuItem onClick={() => setTransport('car')}>
                <MenuIcon>
                  <CarIcon />
                </MenuIcon>
                Voiture
              </MenuItem>
              <MenuItem onClick={() => setTransport('flight')}>
                <MenuIcon>
                  <PlaneIcon />
                </MenuIcon>
                Avion
              </MenuItem>
            </MenuList>
          </Menu>

          <Button
            onClick={submitChoice}
            isDisabled={isGo && !transportChoice}
            variant="tertiary">
            Valider
          </Button>
        </Choice>
      ) : (
        <RatingWrapper>
          <Text fontSize="sm" color="white">
            Êtes-vous satisfait.e de votre expérience Stay or Go ?
          </Text>
          <Rating
            size={18}
            scale={5}
            fillColor={colors.yellow}
            emptyColor={colors.black20}
            isDisabled={hasValidatedSurvey}
            onChange={(value) => handleSurvey(value)}
          />
        </RatingWrapper>
      )}

      <ModalSurvey
        links={links}
        initialRating={initialRating}
        isOpen={isOpen}
        onClose={onClose}
        onValidate={() => setHasValidatedSurvey(true)}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background: ${colors.primary200};
  border-radius: 16px;
  display: flex;
`;
const Description = styled.div`
  padding: 16px;
  flex: 2;
`;
const Choice = styled.div`
  background: ${colors.primary};
  padding: 16px;
  border-radius: 0 16px 18px 0;
  display: flex;
  align-items: center;
`;
const RatingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  padding: 0 16px;
`;
const MenuIcon = styled.div`
  background: ${colors.primary10};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-right: 12px;
  svg {
    path {
      fill: ${colors.primary};
    }
  }
`;
