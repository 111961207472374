import { ReactComponent as CalendarIcon } from '@assets/icons/calendar-black.svg';
import { ReactComponent as LocationIcon } from '@assets/icons/location.svg';
import { Box, Heading, Text } from '@chakra-ui/react';
import UserSummary from '@components/UserSummary';
import { formatDate, formatTime } from '@utils//format';
import { Event } from '@utils/types';
import styled from 'styled-components';

type PanelEventInfosProps = {
  event: Event;
};

export default function PanelEventInfos({ event }: PanelEventInfosProps) {
  return (
    <EventInfos>
      <Text fontSize="sm" color="black80" mb="12px" textTransform="capitalize">
        {event.motive}
      </Text>
      <Heading size="lg" color="primary" mb="12px">
        {event.name}
      </Heading>
      <Text fontSize="sm" color="black80">
        {event.description}
      </Text>

      <Box mt="12px" pt="12px" borderTop="1px solid" borderColor="black20">
        <Text fontSize="12px" textTransform="uppercase" color="black40" mb="4px">
          Créé le {formatDate(event.createdAt)} par
        </Text>
        <UserSummary
          name={event.creator.name}
          subText={event.creator.role}
          pictureURL={event.creator.profilePicture}
        />
        <EventDetails>
          <Detail>
            <CalendarIcon />
            <Box ml="12px">
              <Text fontSize="sm">{formatDate(event.dateFrom)}</Text>
              <Text fontSize="sm" color="black40">
                {formatTime(event.dateFrom)} - {formatTime(event.dateTo)}
              </Text>
            </Box>
          </Detail>
          <Detail>
            <LocationIcon />
            <Box ml="12px">
              <Text fontSize="sm">{event.location.address}</Text>
              <Text fontSize="sm" color="black40">
                {event.location.postalCode} {event.location.city}
              </Text>
            </Box>
          </Detail>
        </EventDetails>
      </Box>
    </EventInfos>
  );
}

const EventInfos = styled.div`
  border-radius: 16px;
  background: white;
  padding: 16px;
`;

const EventDetails = styled.div``;
const Detail = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;
