export const times = [
  { label: '00:00', hours: 0, minutes: 0 },
  { label: '00:15', hours: 0, minutes: 15 },
  { label: '00:30', hours: 0, minutes: 30 },
  { label: '00:45', hours: 0, minutes: 45 },
  { label: '01:00', hours: 1, minutes: 0 },
  { label: '01:15', hours: 1, minutes: 15 },
  { label: '01:30', hours: 1, minutes: 30 },
  { label: '01:45', hours: 1, minutes: 45 },
  { label: '02:00', hours: 2, minutes: 0 },
  { label: '02:15', hours: 2, minutes: 15 },
  { label: '02:30', hours: 2, minutes: 30 },
  { label: '02:45', hours: 2, minutes: 45 },
  { label: '03:00', hours: 3, minutes: 0 },
  { label: '03:15', hours: 3, minutes: 15 },
  { label: '03:30', hours: 3, minutes: 30 },
  { label: '03:45', hours: 3, minutes: 45 },
  { label: '04:00', hours: 4, minutes: 0 },
  { label: '04:15', hours: 4, minutes: 15 },
  { label: '04:30', hours: 4, minutes: 30 },
  { label: '04:45', hours: 4, minutes: 45 },
  { label: '05:00', hours: 5, minutes: 0 },
  { label: '05:15', hours: 5, minutes: 15 },
  { label: '05:30', hours: 5, minutes: 30 },
  { label: '05:45', hours: 5, minutes: 45 },
  { label: '06:00', hours: 6, minutes: 0 },
  { label: '06:15', hours: 6, minutes: 15 },
  { label: '06:30', hours: 6, minutes: 30 },
  { label: '06:45', hours: 6, minutes: 45 },
  { label: '07:00', hours: 7, minutes: 0 },
  { label: '07:15', hours: 7, minutes: 15 },
  { label: '07:30', hours: 7, minutes: 30 },
  { label: '07:45', hours: 7, minutes: 45 },
  { label: '08:00', hours: 8, minutes: 0 },
  { label: '08:15', hours: 8, minutes: 15 },
  { label: '08:30', hours: 8, minutes: 30 },
  { label: '08:45', hours: 8, minutes: 45 },
  { label: '09:00', hours: 9, minutes: 0 },
  { label: '09:15', hours: 9, minutes: 15 },
  { label: '09:30', hours: 9, minutes: 30 },
  { label: '09:45', hours: 9, minutes: 45 },
  { label: '10:00', hours: 10, minutes: 0 },
  { label: '10:15', hours: 10, minutes: 15 },
  { label: '10:30', hours: 10, minutes: 30 },
  { label: '10:45', hours: 10, minutes: 45 },
  { label: '11:00', hours: 11, minutes: 0 },
  { label: '11:15', hours: 11, minutes: 15 },
  { label: '11:30', hours: 11, minutes: 30 },
  { label: '11:45', hours: 11, minutes: 45 },
  { label: '12:00', hours: 12, minutes: 0 },
  { label: '12:15', hours: 12, minutes: 15 },
  { label: '12:30', hours: 12, minutes: 30 },
  { label: '12:45', hours: 12, minutes: 45 },
  { label: '13:00', hours: 13, minutes: 0 },
  { label: '13:15', hours: 13, minutes: 15 },
  { label: '13:30', hours: 13, minutes: 30 },
  { label: '13:45', hours: 13, minutes: 45 },
  { label: '14:00', hours: 14, minutes: 0 },
  { label: '14:15', hours: 14, minutes: 15 },
  { label: '14:30', hours: 14, minutes: 30 },
  { label: '14:45', hours: 14, minutes: 45 },
  { label: '15:00', hours: 15, minutes: 0 },
  { label: '15:15', hours: 15, minutes: 15 },
  { label: '15:30', hours: 15, minutes: 30 },
  { label: '15:45', hours: 15, minutes: 45 },
  { label: '16:00', hours: 16, minutes: 0 },
  { label: '16:15', hours: 16, minutes: 15 },
  { label: '16:30', hours: 16, minutes: 30 },
  { label: '16:45', hours: 16, minutes: 45 },
  { label: '17:00', hours: 17, minutes: 0 },
  { label: '17:15', hours: 17, minutes: 15 },
  { label: '17:30', hours: 17, minutes: 30 },
  { label: '17:45', hours: 17, minutes: 45 },
  { label: '18:00', hours: 18, minutes: 0 },
  { label: '18:15', hours: 18, minutes: 15 },
  { label: '18:30', hours: 18, minutes: 30 },
  { label: '18:45', hours: 18, minutes: 45 },
  { label: '19:00', hours: 19, minutes: 0 },
  { label: '19:15', hours: 19, minutes: 15 },
  { label: '19:30', hours: 19, minutes: 30 },
  { label: '19:45', hours: 19, minutes: 45 },
  { label: '20:00', hours: 20, minutes: 0 },
  { label: '20:15', hours: 20, minutes: 15 },
  { label: '20:30', hours: 20, minutes: 30 },
  { label: '20:45', hours: 20, minutes: 45 },
  { label: '21:00', hours: 21, minutes: 0 },
  { label: '21:15', hours: 21, minutes: 15 },
  { label: '21:30', hours: 21, minutes: 30 },
  { label: '21:45', hours: 21, minutes: 45 },
  { label: '22:00', hours: 22, minutes: 0 },
  { label: '22:15', hours: 22, minutes: 15 },
  { label: '22:30', hours: 22, minutes: 30 },
  { label: '22:45', hours: 22, minutes: 45 },
  { label: '23:00', hours: 23, minutes: 0 },
  { label: '23:15', hours: 23, minutes: 15 },
  { label: '23:30', hours: 23, minutes: 30 },
  { label: '23:45', hours: 23, minutes: 45 },
];
