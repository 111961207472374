import { Box, Heading, Text } from '@chakra-ui/react';
import { colors } from '@theme/colors';
import styled from 'styled-components';

export default function CardVotes() {
  return (
    <Wrapper>
      <Section>
        <Heading size="xl">Go</Heading>

        <Text fontSize="sm" color="black60" lineHeight="20px">
          Les votants préfèrent participer en présentiel
        </Text>
      </Section>
      <Section>
        <Box display="flex" alignItems="center">
          <BulletStay />
          <Text fontSize="md" fontWeight="bold" color="black60" ml="8px">
            STAY
          </Text>
        </Box>
        <Box>
          <Heading size="lg" color="black">
            25%
          </Heading>
        </Box>
      </Section>
      <Section>
        <Box display="flex" alignItems="center">
          <BulletGo />
          <Text fontSize="md" fontWeight="bold" color="black60" ml="8px">
            GO
          </Text>
        </Box>
        <Box>
          <Heading size="lg" color="black">
            75%
          </Heading>
        </Box>
      </Section>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  border-radius: 16px;
  background: ${colors.white};
  padding: 16px;
  display: flex;
  min-height: 130px;
`;
const Section = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const BulletStay = styled.div`
  border-radius: 50%;
  background: ${colors.primary};
  width: 16px;
  height: 16px;
`;
const BulletGo = styled(BulletStay)`
  background: ${colors.blue};
`;
