import { colors } from '@theme/colors';
import styled from 'styled-components';

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 40px;
`;
export const FormRow = styled.div<{ inline?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  padding: 16px;
  border-radius: 16px;
  background-color: ${colors.white};
`;
export const TitleForm = styled.p`
  font-size: 16px;
  text-align: center;
  white-space: pre-line;
  padding: 16px;
`;

export const Content = styled.div`
  background-color: ${colors.white};
  margin-top: 16px;
  padding-top: 32px;
  padding-bottom: 80px;
  display: flex;
  justify-content: center;
`;

export const TitleRow = styled.p`
  font-size: 16px;
  margin: 0;
  color: ${colors.black60};
`;

export const RadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const FormError = styled.p`
  color: ${colors.red};
`;
