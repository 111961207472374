import {
  Button,
  Modal as CustomModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps as ChakraModalProps,
} from '@chakra-ui/react';

type ModalProps = ChakraModalProps & {
  isOpen: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  children?: React.ReactNode;
  type?: 'confirm' | 'info';
  width?: string;
  height?: string;
  maxWidth?: string;
  maxHeight?: string;
};

export default function Modal({
  isOpen,
  onClose,
  onConfirm,
  children,
  type,
  width,
  height,
  maxWidth,
  maxHeight,
  ...props
}: ModalProps) {
  return (
    <CustomModal isCentered isOpen={isOpen} onClose={onClose} {...props}>
      <ModalOverlay />
      <ModalContent w={width} h={height} maxW={maxWidth} maxH={maxHeight}>
        <ModalCloseButton />
        {type ? (
          <>
            {children}

            <ModalFooter gap="16px" display="flex">
              {type === 'confirm' ? (
                <>
                  <Button width="100%" variant="outline" onClick={onClose}>
                    Annuler
                  </Button>
                  <Button width="100%" onClick={onConfirm}>
                    Valider
                  </Button>
                </>
              ) : (
                <Button width="100%" variant="primary" onClick={onClose}>
                  Ok
                </Button>
              )}
            </ModalFooter>
          </>
        ) : (
          children
        )}
      </ModalContent>
    </CustomModal>
  );
}

export { Modal, ModalHeader, ModalBody, ModalFooter };
