import { RegistrationFlow, UpdateRegistrationFlowBody } from '@ory/client';
import { UserAuthCard } from '@ory/elements';
import { sdk, sdkError } from '@providers/ory/sdk';
import { routes } from '@utils/consts';
import { translateFlow } from '@utils/translateFlow';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import LoaderAccount from './LoaderAccount';
import * as S from './StyledAccount';

export const RegistrationCard = () => {
  const [flow, setFlow] = useState<RegistrationFlow | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const returnTo = import.meta.env.VITE_APP_URL;
  const loginChallenge = searchParams.get('login_challenge');
  const navigate = useNavigate();

  const getFlow = useCallback(
    (flowId: string) =>
      sdk
        .getRegistrationFlow({ id: flowId })
        .then(({ data: flow }) => setFlow(flow))
        .catch(sdkErrorHandler),
    [],
  );

  const sdkErrorHandler = sdkError(getFlow, setFlow, routes.REGISTRATION, true);

  const createFlow = () => {
    sdk
      .createBrowserRegistrationFlow({
        ...(returnTo && { returnTo: returnTo }),
        ...(loginChallenge && { loginChallenge: loginChallenge }),
      })
      .then(({ data: flow }) => {
        setSearchParams({ ['flow']: flow.id });
        setFlow(translateFlow(flow));
      })
      .catch(sdkErrorHandler);
  };

  const submitFlow = (body: UpdateRegistrationFlowBody) => {
    // something unexpected went wrong and the flow was not set
    if (!flow) return navigate(routes.REGISTRATION, { replace: true });

    sdk
      .updateRegistrationFlow({
        flow: flow.id,
        updateRegistrationFlowBody: body,
      })
      .then(({ data }) => {
        if ('continue_with' in data) {
          for (const cw of data.continue_with ?? []) {
            if (cw.action === 'show_verification_ui') {
              const search = new URLSearchParams();
              search.set('flow', cw.flow.id);
              navigate(
                {
                  pathname: routes.VERIFICATION,
                  search: search.toString(),
                },
                { replace: true },
              );
              return;
            }
          }
        }

        navigate(0);
      })
      .catch(sdkErrorHandler);
  };

  useEffect(() => {
    createFlow();
  }, [navigate]);

  return flow ? (
    <S.AccountCard>
      <UserAuthCard
        className="authCard"
        flowType="registration"
        flow={flow}
        additionalProps={{
          loginURL: {
            handler: () => {
              const search = new URLSearchParams();
              flow.return_to && search.set('return_to', flow.return_to);
              flow.oauth2_login_challenge &&
                search.set('login_challenge', flow.oauth2_login_challenge);
              navigate(
                { pathname: routes.LOGIN, search: search.toString() },
                { replace: true },
              );
            },
          },
        }}
        includeScripts={true}
        onSubmit={({ body }) => submitFlow(body as UpdateRegistrationFlowBody)}
      />
    </S.AccountCard>
  ) : (
    <LoaderAccount />
  );
};
