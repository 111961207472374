import { ReactComponent as CloseIcon } from '@assets/icons/close.svg';
import { ReactComponent as InviteIcon } from '@assets/icons/profile-add.svg';
import { Box, Button, Heading, Input, Text } from '@chakra-ui/react';
import { colors } from '@theme/colors';
import styled from 'styled-components';

export default function InviteParticipants() {
  return (
    <Wrapper>
      <Box display="flex" alignItems="center" mb="40px" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <InviteIcon />
          <Heading ml="8px" size="sm" color="primary">
            Inviter des participants
          </Heading>
        </Box>

        <CloseIcon />
      </Box>

      <Text fontSize="sm" color="black" my="8px">
        Ajouter un participant
      </Text>
      <Input placeholder="prenom.nom@email.com" />

      <Button width="100%" mt="20px">
        Ajouter{' '}
      </Button>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  border-radius: 16px;
  background: ${colors.white};
  padding: 16px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
`;
// const Image = styled.div`
//   position: absolute;
//   right: 0;
//   bottom: 0;
//   height: 100%;
//   display: flex;
//   align-items: end;
//   svg {
//     height: 90%;
//     width: 200px;
//   }
// `;
