import { sdk as ory, sdkError } from '@providers/ory/sdk';
import { useAuthStore } from '@stores/authStore';
import { routes } from '@utils/consts';
import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

export const AuthProvider = () => {
  const { setLogoutUrl } = useAuthStore.getState();
  const logoutUrl = useAuthStore((state: any) => state.logoutUrl);
  const location = useLocation();
  const sdkErrorHandler = sdkError(undefined, undefined, routes.LOGIN);
  const createLogoutFlow = () => {
    ory
      .createBrowserLogoutFlow(undefined, {
        params: {
          return_url: '/',
        },
      })
      .then(({ data }) => {
        console.log('logout flow created ', data);
        setLogoutUrl(data.logout_url);
      })
      .catch(sdkErrorHandler);
  };

  useEffect(() => {
    if (
      !logoutUrl &&
      location.pathname !== routes.REGISTRATION &&
      location.pathname !== routes.VERIFICATION &&
      location.pathname !== routes.RECOVERY
    ) {
      ory
        .toSession()
        .then(() => {
          createLogoutFlow();
        })
        .catch(sdkErrorHandler);
    }
  }, [location.pathname]);

  return (
    <>
      <Outlet />
    </>
  );
};
