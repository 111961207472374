import { ReactComponent as AddIcon } from '@assets/icons/add.svg';
import { ReactComponent as CalendarIcon } from '@assets/icons/calendar.svg';
import { ReactComponent as LogoIcon } from '@assets/icons/stayorgo-logo.svg';
import { Box, Button, ModalHeader } from '@chakra-ui/react';
import { Modal } from '@components/Modal';
import { useModal } from '@hooks/useModal';
import { sdk as ory } from '@providers/ory/sdk';
import { useAuthStore } from '@stores/authStore';
import { routes } from '@utils/consts';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

export default function Header() {
  const { isOpen, toggleModal } = useModal(false);
  const path = useLocation().pathname;
  const navigate = useNavigate();

  const logoutUrl = useAuthStore((state: any) => state.logoutUrl);
  const setLogoutUrl = useAuthStore((state: any) => state.setLogoutUrl);
  const handleLogout = async () => {
    try {
      const { data: flow } = await ory.createBrowserLogoutFlow();
      await ory.updateLogoutFlow({
        token: flow.logout_token,
      });
      toggleModal();
      setLogoutUrl('');
      navigate(routes.LOGIN);
    } catch (error) {
      console.error('Error logging out', error);
    }
  };

  return (
    <Wrapper path={path}>
      <Link to="/">
        <LogoIcon />
      </Link>
      <Modal
        isOpen={isOpen}
        onConfirm={() => handleLogout()}
        onClose={toggleModal}
        type="confirm">
        <ModalHeader textAlign="center" margin="24px">
          Êtes-vous sûr de vouloir vous déconnecter ?
        </ModalHeader>
      </Modal>

      <Box>
        {logoutUrl && (
          <>
            <Link to="/">
              <Button leftIcon={<CalendarIcon />} variant="secondary" mr="8px">
                Mes évènements
              </Button>
            </Link>

            <Link to="/create-event">
              <Button leftIcon={<AddIcon />}>Créer un évènement</Button>
            </Link>
            <Button variant="secondary" onClick={() => toggleModal()}>
              Logout
            </Button>
          </>
        )}
      </Box>
    </Wrapper>
  );
}

const Wrapper = styled.div<{ path: string }>`
  padding: 20px;
  align-items: center;
  justify-content: space-between;
  background: white;
  background: ${(props) => (props.path === '/' ? 'white' : 'transparent')};
  display: ${(props) => (props.path === '/login' ? 'none' : 'flex')};
`;
