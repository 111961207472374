import { create } from 'zustand';

type AuthStoreProps = {
  logoutUrl?: string;
  setLogoutUrl: (url: string) => void;
};

export const useAuthStore = create<AuthStoreProps>((set) => ({
  logoutUrl: undefined,
  setLogoutUrl: (url: string) => set(() => ({ logoutUrl: url })),
}));
