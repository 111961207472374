import { defineStyleConfig } from '@chakra-ui/react';
import { colors } from '@theme/colors';

export const Button = defineStyleConfig({
  baseStyle: {
    borderRadius: '38px',
    boxSizing: 'border-box',
    px: '20px',
    py: '24px',
  },

  sizes: {
    sm: {
      fontSize: 'sm',
    },
    md: {
      fontSize: 'md',
    },
  },

  variants: {
    primary: {
      bg: colors.primary,
      color: colors.white,
      _hover: {
        bg: colors.primary200,
      },
    },
    secondary: {
      bg: 'transparent',
      color: colors.primary,
      _hover: {
        bg: colors.primary10,
      },
    },
    tertiary: {
      bg: 'white',
      color: colors.primary,
      _hover: {
        bg: colors.primary10,
      },
    },
    outline: {
      bg: 'white',
      color: colors.primary,
      borderColor: colors.primary,
      borderStyle: 'solid',
      borderWidth: '2px',
      _hover: {
        bg: colors.primary10,
      },
    },
    opacity10: {
      bg: 'rgba(266,255,255, 0.1)',
      color: colors.white,
      px: 6,
      py: 4,
      _hover: {
        bg: 'rgba(266,255,255, 0.3)',
      },
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'primary',
  },
});

export const Container = defineStyleConfig({
  baseStyle: {
    bg: '#fff',
    borderRadius: '16px',
    margin: '0',
    padding: '20px',
  },
});

export const Heading = defineStyleConfig({
  sizes: {
    xl: {
      // Heading4
      fontSize: '32px',
    },
    lg: {
      // Heading5
      fontSize: '24px',
    },
    md: {
      // Heading6
      fontSize: '20px',
    },
    sm: {
      // Heading7
      fontSize: '16px',
    },
  },
});
export const Tabs = defineStyleConfig({
  variants: {
    primary: {
      tab: {
        fontSize: '16px',
        _selected: {
          color: colors.primary,
          borderColor: colors.primary,
          borderBottom: '2px solid',
          padding: '16px',
        },
      },
      tablist: {
        color: colors.black40,
      },
    },
  },
  defaultProps: {
    variant: 'primary',
  },
});

export const Input = defineStyleConfig({
  baseStyle: {
    field: {
      height: '48px',
      fontSize: '16px',
      bg: colors.grey,
      color: colors.black,
      borderRadius: '8px',
      borderColor: colors.grey,
      borderWidth: '1px ',
      borderStyle: 'solid',

      _invalid: {
        borderColor: colors.red,
        bg: colors.redBackgroundLight,
      },

      _placeholder: {
        color: colors.black40,
      },
    },
  },
  variants: {
    primary: {
      field: {
        _focus: {
          borderColor: colors.blue,
        },
      },
    },
    search: {
      field: {
        borderColor: colors.black20,
        borderRadius: '16px',
        bg: colors.white,
        _focus: {
          borderColor: colors.blue,
          bg: colors.white,
        },
      },
    },
  },

  defaultProps: {
    variant: 'primary',
  },
});

export const Select = defineStyleConfig({
  baseStyle: {
    field: {
      fontSize: '16px',
      height: '48px',
      bg: colors.grey,
      color: colors.black,
      borderRadius: '8px',
      borderColor: colors.grey,
      borderWidth: '1px ',
      borderStyle: 'solid',

      _placeholder: {
        color: colors.black,
      },
      _invalid: {
        borderColor: colors.red,
        bg: colors.redBackgroundLight,
      },
    },
  },
  variants: {
    primary: {
      field: {
        _focus: {
          borderColor: colors.blue,
        },
      },
    },
    secondary: {
      field: {
        bg: colors.white,
        borderColor: colors.white,
        fontWeight: 'bold',
        paddingLeft: 0,
      },
    },
  },

  defaultProps: {
    variant: 'primary',
  },
});

export const FormLabel = defineStyleConfig({
  baseStyle: {
    color: colors.black,
    fontSize: '14px',
    fontWeight: 'normal',
  },
});
export const Textarea = defineStyleConfig({
  variants: {
    primary: {
      fontSize: '16px',
      bg: colors.grey,
      color: colors.black,
      borderRadius: '8px',
      borderColor: colors.grey,
      borderWidth: '1px ',
      borderStyle: 'solid',
      background: colors.grey,
      _focus: {
        borderColor: colors.blue,
      },
      field: {},
    },
  },

  defaultProps: {
    variant: 'primary',
  },
});

export const Text = defineStyleConfig({
  variants: {
    primary: {
      fontSize: '14px',
      color: colors.black,
    },
    error: {
      fontSize: '14px',
      fontWeight: 'bold',
      mt: '8px',
      color: colors.red,
    },
  },

  defaultProps: {
    variant: 'primary',
  },
});
export const Menu = defineStyleConfig({
  baseStyle: {
    list: {
      py: '4',
      borderRadius: '16px',
      border: 'none',
      bg: 'white',
      boxShadow: '0px 0px 20px 0px rgba(0,0,0,0.34)',
    },
    item: {
      color: 'black',
      _hover: {
        bg: 'primary10',
      },
    },
  },
});
export const List = defineStyleConfig({
  baseStyle: {
    container: {
      py: '4',
      borderRadius: '16px',
      border: 'none',
      bg: 'white',
      boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.1)',
    },
    item: {
      color: 'black',
      cursor: 'pointer',
      padding: '4px 16px',
      _hover: {
        bg: 'primary10',
      },
    },
  },
});
export const Checkbox = defineStyleConfig({
  baseStyle: {
    container: {},
    control: {
      backgroundColor: 'grey',
      padding: '10px',
      borderRadius: '6px',
      borderColor: colors.black40,
      borderWidth: '1px ',
      borderStyle: 'solid',
      _checked: {
        backgroundColor: 'primary',
      },
    },
  },
  variants: {
    primary: {
      container: {},
      control: {
        // this will style the MenuItem and MenuItemOption components
      },
    },
  },

  defaultProps: {
    variant: 'primary',
  },
});
export const Modal = defineStyleConfig({
  baseStyle: {
    dialog: { borderRadius: '16px' },
  },
});
export const Radio = defineStyleConfig({
  baseStyle: {
    control: {
      border: '2px solid',
      borderColor: colors.black60,
      bg: 'white',
      width: '20px',
      height: '20px',

      _checked: {
        bg: 'white',

        _before: {
          width: '80%',
          height: '80%',
          borderRadius: '50%',
          bg: 'primary',
        },
      },
    },
  },
});
export const Calendar = {
  CalendarControl: {
    parts: ['button'],

    baseStyle: {
      button: {
        h: 2,
        px: 2,
        py: 4,
        heigh: '5px',
        rounded: 'none',
        fontSize: 'sm',
        color: 'primary',
        bgColor: 'transparent',

        _hover: {
          bgColor: 'primary50',
        },

        _focus: {
          outline: 'none',
        },
      },
    },
  },
  CalendarDay: {
    baseStyle: {
      color: 'black',

      _hover: {
        bgColor: 'primary50',
      },
      _active: {
        bgColor: 'primary50',
      },
      _focus: {
        bgColor: 'primary50',
      },
    },
    variants: {
      selected: {
        bgColor: 'primary',
        color: 'white',
      },
    },
  },
};
