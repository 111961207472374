export const documentation = {
  criterias: [
    {
      id: 'co2',
      title: 'CO2',
      content:
        'Emissions de gaz à effet de serre générées par le transport principal. Ce critère est exprimé en kilogrammes de CO2 équivalent (kgCO2e). Les facteurs d’émissions utilisés sont ceux de la Base Empreinte, et le barème d’évaluation de ce critère est basé sur les habitudes de mobilité de votre organisation.',
    },
    {
      id: 'cost',
      title: 'Coût',
      content:
        'Dépenses liées à la réservation (transport et hébergement). Ce critère est exprimé en euro (€). Il est estimé sur la base des coûts de réservation moyens des offres disponibles. Le barème d’évaluation de ce critère est basé sur les habitudes de mobilité de votre organisation.',
    },
    {
      id: 'travelTime',
      title: 'Durée du trajet',
      content:
        'Durée porte à porte du déplacement, comprenant les transports de liaison, les temps d’embarquement et de débarquement, le temps d’attente, le transport principal. Ce critère est exprimé en nombre d’heures (h). Le barème d’évaluation de ce critère est basé sur les habitudes de mobilité de votre organisation.',
    },
    {
      id: 'financialValue',
      title: 'Valeur financière',
      content:
        'Il s’agit des retombées économiques potentielles du RDV évalué. Ce critère est directement donné par l’utilisateur sur la base d’une échelle qualitative (allant de “très faible” à “très forte”).',
    },
    {
      id: 'social',
      title: 'Lien social',
      content:
        'Dépenses liées à la réservation (transport et hébergement). Ce critère est exprimé en euro (€). Il est estimé sur la base des coûts de réservation moyens des offres disponibles. Le barème d’évaluation de ce critère est basé sur les habitudes de mobilité de votre organisation.',
    },
    {
      id: 'stress',
      title: 'Stress',
      content:
        'Ce critère est déduit à partir du mode de transport principal. Le train est jugé comme étant le mode de transport le moins stressant, suivi par la voiture, et enfin l’avion. ',
    },
  ],
};
