import { Modal, ModalBody, ModalHeader } from '@components/Modal';
import SurverForm from '@components/SurveyForm/SurveyForm';
import { colors } from '@theme/colors';
import styled from 'styled-components';

type ModalParticipantsProps = {
  isOpen: boolean;
  onClose: () => void;
  onValidate: () => void;
  initialRating: number | null;
  links: any;
};

export default function ModalSurvey({
  initialRating,
  isOpen,
  onClose,
  onValidate,
  links,
}: ModalParticipantsProps) {
  const handleValidate = () => {
    onValidate();
    onClose();
  };
  return (
    <Modal width="70%" maxWidth="850px" isOpen={isOpen} onClose={onClose} size="auto">
      <ModalHeader>Sondage</ModalHeader>

      <StyledModalBody>
        <SurverForm
          onValidate={handleValidate}
          initialRating={initialRating}
          links={links}
        />
      </StyledModalBody>
    </Modal>
  );
}

const StyledModalBody = styled(ModalBody)`
  max-height: 80vh;
  overflow-y: auto;
  background-color: ${colors.primary10};
`;
