import { isAfter, isEqual } from 'date-fns';
import * as yup from 'yup';

export const schema = yup.object().shape({
  name: yup.string().required('Le nom est requis'),
  motive: yup.string().required('Le motif est requis'),
  location: yup.object().required("Choisir le lieu de l'évènement").nullable(),
  type: yup.string().required("Choisir le type d'évènement"),
  dateFrom: yup.string().required('La date de début est requise'),
  dateTo: yup
    .string()
    .required('La date de fin est requise')
    .test(
      'is-greater',
      'La date de fin doit être supérieure à la date de début',
      function (dateTo: any) {
        const { dateFrom } = this.parent;
        const after = isAfter(new Date(dateTo), new Date(dateFrom));
        const equal = isEqual(new Date(dateTo), new Date(dateFrom));
        return after || equal;
      },
    ),
  locationDeparture: yup
    .object()
    .when('type', {
      is: 'traveler',
      then: yup.object().required('La ville de départ est requise').nullable(),
    })
    .nullable(),
});

export const schemaMeetings = yup.object().shape({
  meetingsNumber: yup
    .number()
    .when('type', {
      is: 'traveler',
      then: yup.number().typeError('Le nombre de réunions est requis').required(),
    })
    .nullable(),
});
