import '@ory/elements/style.css';

import Header from '@components/Header';
import Account from '@features/account/Account';
import { RecoveryCard } from '@features/account/components/RecoveryCard';
import { RegistrationCard } from '@features/account/components/RegistrationCard';
import { SettingsCard } from '@features/account/components/SettingsCard';
import { VerificationCard } from '@features/account/components/VerificationCard';
import Login from '@features/account/Login';
import AssessmentOrganiser from '@features/assessment/assessment-organiser/AssessmentOrganiser';
import AssessmentTraveler from '@features/assessment/assessment-traveler/AssessmentTraveler';
import Documentation from '@features/documentation/Documentation';
import EventCreationForm from '@features/event-creation-form/EventCreationForm';
import EventsDashboard from '@features/events-dashboard/EventsDashboard';
import { CustomTranslations, IntlProvider } from '@ory/elements';
import { AuthProvider } from '@providers/ory/AuthProvider';
import { customTranslations } from '@providers/ory/customTranslations';
import { routes } from '@utils/consts';
import i18n from 'i18next';
import { ReactNode } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

const renderPage = (children: ReactNode) => (
  <>
    <Header />
    {children}
  </>
);

const router = createBrowserRouter([
  {
    path: routes.HOME,
    element: <AuthProvider />,
    children: [
      {
        path: routes.HOME,
        Component: () => renderPage(<EventsDashboard />),
      },
      {
        path: routes.LOGIN,
        Component: () => renderPage(<Login />),
      },

      {
        path: routes.REGISTRATION,
        Component: () => renderPage(<Account card={<RegistrationCard />} />),
      },

      {
        path: routes.VERIFICATION,
        Component: () => renderPage(<Account card={<VerificationCard />} />),
      },
      {
        path: routes.RECOVERY,
        Component: () => renderPage(<Account card={<RecoveryCard />} />),
      },
      {
        path: routes.SETTINGS,
        Component: () => renderPage(<Account card={<SettingsCard />} />),
      },
      {
        path: routes.DOCUMENTATION,
        Component: () => renderPage(<Documentation />),
      },

      {
        path: routes.CREATE_EVENT,
        Component: () => renderPage(<EventCreationForm />),
      },
      {
        path: `${routes.ASSESSMENT_TRAVELER}/:id`,
        Component: () => renderPage(<AssessmentTraveler />),
      },
      {
        path: `${routes.ASSESSMENT_ORGANIZER}/:id`,
        Component: () => renderPage(<AssessmentOrganiser />),
      },
    ],
  },
]);

const App = () => {
  const language: any = i18n.language;

  return (
    <>
      <IntlProvider<CustomTranslations>
        locale={language}
        defaultLocale="en"
        customTranslations={customTranslations}>
        <RouterProvider router={router} />
      </IntlProvider>
    </>
  );
};

export default App;
