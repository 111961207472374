import { ReactComponent as ArrowIcon } from '@assets/icons/arrow-down.svg';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
} from '@chakra-ui/react';
import { Heading } from '@chakra-ui/react';
import { colors } from '@theme/colors';
import { useState } from 'react';
import styled from 'styled-components';

import { documentation } from './documentionData';

export default function Login() {
  const [selectedSection, setSelectedSection] = useState(0);

  const handleSectionChange = (index: number) => {
    setSelectedSection(index);
  };

  return (
    <Documentation>
      <Heading color="primary" margin="16px 16px 32px 16px" fontSize="40px">
        Documentation
      </Heading>

      <Content>
        <MenuWrapper>
          <Accordion defaultIndex={0}>
            <StyledAccordionItem>
              <StyledAccordionButton onClick={() => handleSectionChange(0)}>
                {'Méthodologie'}
                <StyledArrowIcon />
              </StyledAccordionButton>
            </StyledAccordionItem>
            <StyledAccordionItem>
              <StyledAccordionButton onClick={() => handleSectionChange(1)}>
                {"Critères d'évaluation"}
                <StyledArrowIcon />
              </StyledAccordionButton>

              <AccordionPanel pb={4}>
                {documentation.criterias.map((criteria) => (
                  <ItemPanel href={`#${criteria.id}`} key={criteria.id}>
                    {criteria.title}
                  </ItemPanel>
                ))}
              </AccordionPanel>
            </StyledAccordionItem>
          </Accordion>
        </MenuWrapper>
        <Description>
          {selectedSection === 0 && (
            <DescriptionSection>
              <DescriptionTitle>{'Méthodologie'}</DescriptionTitle>

              <SectionWrapper>
                <SectionText>
                  {`L’algorithme de Stay or Go repose sur le calcul d’un indicateur d’essentialité. Cet indicateur est un pourcentage basé sur différents critères. Chaque critère est noté sur une échelle de 1 à 5 sur la base d’une grille d’évaluation. Cette grille peut être soit issue des données de mobilité de votre organisation ou de ressources documentaires externes, soit être basée sur une convention. 
                  
                  Ainsi, les résultats reflètent les habitudes de mobilité de votre organisation. Par exemple, un voyage dont les coûts sont beaucoup plus importants que la moyenne des voyages effectués dans votre organisation aura une mauvaise note sur ce critère. Tandis qu’un voyage dont les émissions de gaz à effet de serre sont moindres par rapport à la moyenne aura une bonne note sur ce critère. L’indicateur d’essentialité est ensuite calculé en faisant la moyenne des notes des différents critères, pondérée par l’importance donnée à chaque critère. 
                
                Cette moyenne est ensuite multipliée par un indicateur lié à la faisabilité des différentes activités en distanciel: c’est ce que nous appelons le “taux de non-substituabilité par la visioconférence”. Ce taux est évidemment propre à chaque motif de déplacement (ou type d'événement), et est issu d’une enquête préliminaire sur les pratiques de mobilité de votre organisation. Au final, l’indicateur d’essentialité est le résultat de la moyenne des notes des critères, pondérée par l’importance donnée à chaque critère et multipliée par le taux de non substituabilité par la visioconférence.`}
                </SectionText>
              </SectionWrapper>
            </DescriptionSection>
          )}
          {selectedSection === 1 && (
            <DescriptionSection>
              <DescriptionTitle>{"Critères d'évaluations"}</DescriptionTitle>
              {documentation.criterias.map((criteria) => (
                <SectionWrapper key={criteria.id} id={criteria.id}>
                  <SectionTitle>{criteria.title}</SectionTitle>
                  <SectionText>{criteria.content}</SectionText>
                </SectionWrapper>
              ))}
            </DescriptionSection>
          )}
        </Description>
      </Content>
    </Documentation>
  );
}

const Documentation = styled.div`
  margin-top: 40px;
`;
const Content = styled.div`
  background-color: ${colors.white};
  margin-top: 16px;
  padding-top: 32px;
  padding-bottom: 80px;
  display: flex;
`;
const MenuWrapper = styled.div`
  width: 380px;
  flex-shrink: 0;
  padding: 0 16px;
`;
const StyledAccordionItem = styled(AccordionItem)`
  border: none;
`;
const StyledArrowIcon = styled(ArrowIcon)`
  transition: transform 0.3s;
  path {
    fill: ${colors.black40};
  }
`;
const StyledAccordionButton = styled(AccordionButton)`
  width: 100%;
  font-size: 20px !important;
  display: flex;
  justify-content: space-between;
  color: ${colors.black60};
  border: none;
  &[aria-expanded='true'] {
    font-weight: bold;
    color: ${colors.black};
    ${StyledArrowIcon} {
      transform: rotate(180deg);
      path {
        fill: ${colors.black};
      }
    }
  }
`;
const ItemPanel = styled.a`
  display: block;
  padding: 8px 8px 8px 16px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
`;
const Description = styled.div`
  padding: 0 40px 16px 0;
  max-width: 1200px;
`;
const DescriptionTitle = styled.h2`
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 16px;
`;
const DescriptionSection = styled.div`
  margin-bottom: 16px;
`;
const SectionWrapper = styled.h3`
  margin-bottom: 16px;
`;
const SectionTitle = styled.h3`
  font-size: 24px;
  font-weight: bold;
`;
const SectionText = styled.p`
  font-size: 16px;
  white-space: pre-line;
`;
