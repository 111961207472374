import LogoGoogle from '@assets/icons/google.png';
import LogoAzure from '@assets/icons/microsoft.png';
import { colors } from '@theme/colors';
import styled from 'styled-components';

export const AccountCard = styled.div`
  display: inline-block;
  width: 100%;
  .authCard *,
  .settingCard * {
    font-family: Roboto, system-ui, sans-serif;
  }
  .authCard {
    background-color: ${colors.white};
    padding: 20px;
    border-radius: 16px;
    max-width: 100%;
  }
  .authCard,
  .settingCard {
    label {
      color: ${colors.black};
    }
    a {
      color: ${colors.primary};
    }
  }
  .settingCard > div {
    gap: 10px;
    h3 {
      font-size: 20px;
      font-weight: bold;
    }
  }
  h2 {
    font-size: 20px;
    font-weight: bold;
  }
  h3 {
    margin: 0;
  }
  input {
    border: 1px solid ${colors.grey};
    background-color: ${colors.grey};
    border-radius: 8px;
    padding: 8px 8px;
  }

  input[type='checkbox']:checked {
    background: ${colors.primary};
  }

  input[type='checkbox']:checked::before {
    content: '';
    display: block;
    width: 7px;
    height: 4px;
    border-left: 3px solid #fff;
    border-bottom: 3px solid #fff;
    transform: rotate(-45deg);
    position: relative;
    left: -5px;
  }

  button {
    background-color: ${colors.primary};
    border-radius: 4px;
    padding: 8px 16px;
    color: ${colors.white} !important;
    border-radius: 36px;
    &:hover {
      background-color: ${colors.primary200};
    }
  }
  button[name='email'] {
    background-color: ${colors.white};
    border: 1px solid ${colors.primary};
    border-radius: 36px;
    padding: 8px 16px;
    color: ${colors.primary};
  }
  button[name='provider'] {
    background-color: ${colors.white};
    padding: 8px 16px;
    color: ${colors.black} !important;
    border: 2px solid ${colors.black20};
    border-radius: 36px;
    &:hover {
      background-color: ${colors.primary10};
    }

    .fa-google {
      width: 20px;
      height: 24px;
      background: url('${LogoGoogle}') no-repeat center center;
      background-size: 100%;
    }
    .fa-azure {
      width: 20px;
      height: 20px;
      background: url('${LogoAzure}') no-repeat center center;
      background-size: 100%;
    }
  }
  a {
    text-decoration: underline;
  }
  hr {
    display: none;
  }
`;
