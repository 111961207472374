import { ReactComponent as ParticipantsIcon } from '@assets/icons/group-black.svg';
import { ReactComponent as PresentialIcon } from '@assets/icons/presential-icon.svg';
import { ReactComponent as RemoteIcon } from '@assets/icons/remote-icon.svg';
import { ReactComponent as TickIcon } from '@assets/icons/tick-verified.svg';
import { Heading, useRadioGroup } from '@chakra-ui/react';
import RadioButton from '@components/RadioButton';
import { criteriasPresential, criteriasRemote } from '@features/assessment//mock';
import CardCriteria from '@features/assessment/CardCriteria';
import { Criteria } from '@utils/types';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import Acceptability from './Acceptability';
import BannerRecommandation from './BannerRecommandation';

type Mode = {
  value: string;
  label: string;
  icon: JSX.Element;
};

export default function ResultsOrganiser() {
  const [criterias, setCriterias] = useState<Criteria[]>([]);
  const [tabParticipation, setTabParticipation] = useState('presential');
  useEffect(() => {
    setCriterias(criteriasPresential);
  }, []);

  const { getRootProps, getRadioProps, setValue } = useRadioGroup({
    name: 'mode',
    defaultValue: 'presential',
    onChange: (value) => {
      if (value === 'remote') setCriterias(criteriasRemote);
      if (value === 'presential') setCriterias(criteriasPresential);
      setTabParticipation(value);
    },
  });

  const modes: Mode[] = [
    { value: 'presential', label: 'Présentiel', icon: <PresentialIcon /> },
    { value: 'remote', label: 'Distanciel', icon: <RemoteIcon /> },
    {
      value: 'participants',
      label: 'Choix des participants',
      icon: <ParticipantsIcon />,
    },
    // { value: 'hybrid', label: 'Hybride', icon: <HybridIcon /> },
  ];

  const handleClickBanner = () => {
    setCriterias(criteriasPresential);
    setTabParticipation('presential');
    setValue('presential');
  };

  return (
    <Wrapper>
      <Heading size="md" color="black" mb="12px">
        Résultats
      </Heading>
      <BannerRecommandation onClick={handleClickBanner} type="presential" />

      <ModeGroup {...getRootProps()}>
        {modes.map((mode) => (
          <RadioButton key={mode.value} {...getRadioProps({ value: mode.value })}>
            {mode.icon}
            {mode.label}
            {mode.value === 'presential' && <StyledTickIcon />}
          </RadioButton>
        ))}
      </ModeGroup>

      <Acceptability type={tabParticipation} />

      <CardsWrapper>
        {criterias.length > 0 &&
          criterias.map((criteria) => (
            <CardCriteria key={criteria.label} criteria={criteria} />
          ))}
      </CardsWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div``;

const ModeGroup = styled.div`
  display: flex;
  margin-bottom: 12px;
  margin-top: 12px;
  div {
    margin-right: 12px;
  }
`;
const CardsWrapper = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 16px;
`;
const StyledTickIcon = styled(TickIcon)`
  position: relative;
  left: 10px;
`;
