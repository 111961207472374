import { Box, Button, Input, Radio, RadioGroup, Textarea } from '@chakra-ui/react';
import Rating from '@components/Rating';
import { yupResolver } from '@hookform/resolvers/yup';
import { colors } from '@theme/colors';
import { api } from '@utils//api';
import { SurveyData } from '@utils/types';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import * as S from './StyledSurveyForm';

type SurveyProps = {
  initialRating: number | null;
  isLoading?: boolean;
  onValidate: () => void;
  links: any;
};

export default function Survey({ initialRating, onValidate, links }: SurveyProps) {
  const [isLoading, setIsLoading] = useState(false);
  const schema = yup.object({
    email: yup.string().email("L'adresse email doit être valide."),
  });

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ratingExperience: initialRating,
      ratingRelevance: null,
      meetingModeReview: '',
      meetingModeReviewComments: '',
      transportModeReview: '',
      transportModeReviewComments: '',
      commentsRelevance: '',
      otherRemark: '',
      email: '',
    },
  });

  const formSubmit = async (data: SurveyData) => {
    setIsLoading(true);
    const payload = {
      survey: data,
    };
    try {
      await api.url(links.survey.href).post(payload);
    } catch (error) {
      console.error("Une erreur s'est produite :", error);
    } finally {
      setIsLoading(false);
      onValidate();
    }
  };

  return (
    <S.FormWrapper onSubmit={handleSubmit(formSubmit)}>
      <S.TitleForm>
        {`Votre avis compte !
Aidez-nous à améliorer notre outil en répondant à ce rapide questionnaire de satisfaction.`}
      </S.TitleForm>
      <S.FormRow>
        <Box display="flex" justifyContent="space-between">
          <S.TitleRow>Êtes-vous satisfait.e de votre expérience Stay or Go ?</S.TitleRow>
          <Controller
            name="ratingExperience"
            control={control}
            render={({ field }) => (
              <Rating
                {...field}
                size={24}
                scale={5}
                defaultValue={initialRating}
                fillColor={colors.yellow}
                emptyColor={colors.black20}
                onChange={(value) => field.onChange(value)}
              />
            )}
          />
        </Box>
      </S.FormRow>

      <S.FormRow>
        <Box display="flex" justifyContent="space-between">
          <S.TitleRow>
            {`Comment évaluez-vous la pertinence des résultats de Stay or Go ?`}
          </S.TitleRow>
          <Controller
            name="ratingRelevance"
            control={control}
            render={({ field }) => (
              <Rating
                {...field}
                size={24}
                scale={5}
                fillColor={colors.yellow}
                emptyColor={colors.black20}
                onChange={(value) => field.onChange(value)}
              />
            )}
          />
        </Box>
      </S.FormRow>

      <S.FormRow>
        <S.TitleRow>
          {`Avez-vous des remarques ou suggestions concernant la pertinence des résultats ?`}
        </S.TitleRow>
        <Textarea {...register('commentsRelevance')} placeholder="Saisir votre texte" />
      </S.FormRow>
      <S.FormRow>
        <S.TitleRow>
          {`En tant que voyageur, avez-vous changé d'avis concernant la modalité de votre
          RDV (présentiel ou distanciel) ?`}
        </S.TitleRow>

        <Controller
          name="meetingModeReview"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <S.RadioWrapper>
                <Radio value="yes">Oui</Radio>
                <Radio value="no">Non</Radio>
                <Radio value="other">Autre</Radio>
              </S.RadioWrapper>
            </RadioGroup>
          )}
        />
        {watch('meetingModeReview') === 'other' && (
          <Textarea
            {...register('meetingModeReviewComments')}
            placeholder="Saisir votre texte"
          />
        )}
      </S.FormRow>
      <S.FormRow>
        <S.TitleRow>
          {`En tant que voyageur, avez-vous changé d'avis concernant le mode de transport à
          choisir (train, avion, voiture) ?`}
        </S.TitleRow>
        <Controller
          name="transportModeReview"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <S.RadioWrapper>
                <Radio value="yes">Oui</Radio>
                <Radio value="no">Non</Radio>
                <Radio value="other">Autre</Radio>
              </S.RadioWrapper>
            </RadioGroup>
          )}
        />
        {watch('transportModeReview') === 'other' && (
          <Textarea
            {...register('transportModeReviewComments')}
            placeholder="Saisir votre texte"
          />
        )}
      </S.FormRow>

      <S.FormRow>
        <S.TitleRow>
          {`Avez-vous d'autres remarques ou suggestions qui nous permettraient d'améliorer
          cet outil et son usage ?`}
        </S.TitleRow>
        <Textarea {...register('otherRemark')} placeholder="Saisir votre texte" />
      </S.FormRow>
      <S.FormRow>
        <S.TitleRow>
          {`Si vous acceptez d'être recontacté.e dans le cadre de ce bêta-test, veuillez
          transmettre votre adresse email`}
        </S.TitleRow>
        <Input {...register('email')} placeholder="Votre email" />
        {errors.email && <S.FormError>{errors.email.message}</S.FormError>}
      </S.FormRow>
      <Button
        isDisabled={isLoading}
        margin="auto"
        minWidth="100px"
        variant="primary"
        size="md"
        type="submit">
        Valider
      </Button>
    </S.FormWrapper>
  );
}
