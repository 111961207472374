import { Heading } from '@chakra-ui/react';
import { Tab, TabList, Tabs } from '@chakra-ui/react';
import styled from 'styled-components';

import EventsUser from './EventsUser';

export default function EventsDashboard() {
  return (
    <Wrapper>
      <Header>
        <HeaderContent>
          <Heading size="lg" mb="12px">
            Mes évènements
          </Heading>

          <Tabs>
            <TabList>
              <Tab>Créés par moi</Tab>
              <Tab>Invitations</Tab>
            </TabList>
          </Tabs>
        </HeaderContent>
      </Header>

      <Content>
        <EventsUser />
      </Content>
    </Wrapper>
  );
}

const Wrapper = styled.div``;
const Header = styled.div`
  background: white;
`;
const HeaderContent = styled.div`
  width: 600px;
  margin: auto;
  padding-top: 32px;
`;
const Content = styled.div`
  width: 700px;
  margin: auto;
  margin-bottom: 100px;
`;
