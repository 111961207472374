import { Skeleton } from '@chakra-ui/react';
import styled from 'styled-components';

export default function LoaderAccount() {
  return (
    <Container>
      <Skeleton borderRadius="16px" width="100%" height="600px" />
    </Container>
  );
}
const Container = styled.div`
  margin-top: 12px;
`;
