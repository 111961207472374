import { UpdateVerificationFlowBody, VerificationFlow } from '@ory/client';
import { UserAuthCard } from '@ory/elements';
import { sdk, sdkError } from '@providers/ory/sdk';
import { routes } from '@utils/consts';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import LoaderAccount from './LoaderAccount';
import * as S from './StyledAccount';

export const VerificationCard = (): JSX.Element => {
  const [flow, setFlow] = useState<VerificationFlow | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const returnTo = import.meta.env.VITE_APP_URL;
  const flowId = searchParams.get('flow');
  const navigate = useNavigate();

  const getFlow = useCallback(
    (flowId: string) =>
      sdk
        .getVerificationFlow({ id: flowId })
        .then(({ data: flow }) => setFlow(flow))
        .catch(sdkErrorHandler),
    [],
  );

  const sdkErrorHandler = sdkError(getFlow, setFlow, routes.VERIFICATION, true);

  const createFlow = () => {
    sdk
      .createBrowserVerificationFlow({
        ...(returnTo && { returnTo: returnTo }),
      })
      .then(({ data: flow }) => {
        setSearchParams({ ['flow']: flow.id });
        setFlow(flow);
      })
      .catch(sdkErrorHandler);
  };

  const submitFlow = (body: UpdateVerificationFlowBody) => {
    if (!flow) return navigate(routes.VERIFICATION, { replace: true });

    sdk
      .updateVerificationFlow({
        flow: flow.id,
        updateVerificationFlowBody: body,
      })
      .then(({ data: flow }) => {
        setFlow(flow);
      })
      .catch(sdkErrorHandler);
  };

  useEffect(() => {
    if (flowId) {
      getFlow(flowId).catch(createFlow);
      return;
    }
    createFlow();
  }, []);

  return flow ? (
    <S.AccountCard>
      <UserAuthCard
        flowType={'verification'}
        flow={flow}
        className="authCard"
        additionalProps={{
          signupURL: {
            handler: () => {
              navigate({ pathname: routes.REGISTRATION }, { replace: true });
            },
          },
        }}
        onSubmit={({ body }) => submitFlow(body as UpdateVerificationFlowBody)}
      />
    </S.AccountCard>
  ) : (
    <LoaderAccount />
  );
};
