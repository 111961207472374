import { ReactComponent as ArrowIcon } from '@assets/icons/arrow-right-2.svg';
import EarthImage from '@assets/icons/earth-image.png';
import { Heading } from '@chakra-ui/react';
import { colors } from '@theme/colors';
import { routes } from '@utils/consts';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export default function BoxInfosCalcul() {
  return (
    <Link to={routes.DOCUMENTATION}>
      <Wrapper>
        <Heading size="sm" w="60%">
          Comment est calculée cette évaluation ?
        </Heading>
        <MoreInfos>
          <Heading size="xs" mr="8px">
            En savoir plus
          </Heading>{' '}
          <ArrowIcon />
        </MoreInfos>
      </Wrapper>
    </Link>
  );
}

const Wrapper = styled.div`
  border-radius: 16px;
  background: ${colors.primary50};
  padding: 16px;
  background-image: url(${EarthImage});
  background-repeat: no-repeat;
  background-position: 200px 13px;
  background-size: 150px;
  max-width: 340px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
`;

const MoreInfos = styled.div`
  display: flex;
  color: ${colors.primary};
`;
