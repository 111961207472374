import Profile3 from '@assets/icons/profile-man1.png';
import Profile4 from '@assets/icons/profile-man2.png';
import Profile1 from '@assets/icons/profile-woman1.png';
import Profile2 from '@assets/icons/profile-woman2.png';

export const criteriasPresential = [
  {
    label: 'CO2',
    icon: 'co2',
    value: '29,6',
    unit: 'kg',
    description:
      "Emissions de CO2 dues au transport et à l'hébergement si tous les invités participent à l'événement en présentiel",
  },
  {
    label: 'Coût',
    icon: 'price',
    value: '860',
    unit: '€',
    description:
      " Dépenses pour le transport et l'hébergement si tous les invités participent à l'événement en présentiel",
  },
  {
    label: 'Lien social',
    icon: 'social',
    value: '3/5',
    description:
      "Valeur sociale non générée par l'événement à cause de la participation des invités en présentiel",
  },
];
export const criteriasRemote = [
  {
    label: 'CO2',
    icon: 'co2',
    value: '0.5',
    unit: 'kg',
    description:
      "Emissions de CO2 dues au transport et à l'hébergement si tous les invités participent à l'événement en distanciel",
  },
  {
    label: 'Coût',
    icon: 'price',
    value: '0',
    unit: '€',
    description:
      " Dépenses pour le transport et l'hébergement si tous les invités participent à l'événement en distanciel",
  },
  {
    label: 'Lien social',
    icon: 'social',
    value: '3/5',
    description:
      "Valeur sociale non générée par l'événement à cause de la participation des invités en distanciel",
  },
];
export const criteriasHybrid = [
  {
    label: 'CO2',
    icon: 'co2',
    value: '0.5',
    unit: 'kg',
    description:
      "Emissions de CO2 dues au transport et à l'hébergement si tous les invités participent à l'événement en distanciel",
  },
  {
    label: 'Coût',
    icon: 'price',
    value: '0',
    unit: '€',
    description:
      " Dépenses pour le transport et l'hébergement si tous les invités participent à l'événement en distanciel",
  },
  {
    label: 'Lien social',
    icon: 'social',
    value: '1.6/5',
    description:
      "Valeur sociale non générée par l'événement à cause de la participation des invités en distanciel",
  },
];
export const criteriasParticipantsChoice = [
  {
    label: 'CO2',
    icon: 'co2',
    value: '364,5',
    unit: 'kg',
    description:
      "Emissions de CO2 dues au transport et à l'hébergement si chaque invité choisit la modalité qu'il préfère",
  },
  {
    label: 'Coût',
    icon: 'price',
    value: '1000',
    unit: '€',
    description:
      "Dépenses pour le transport et l'hébergement si chaque invité choisit la modalité qu'il préfère",
  },
  {
    label: 'Lien social',
    icon: 'social',
    value: '2,5/5',
    description:
      "Valeur sociale générée par l'événement si chaque invité choisit la modalité qu'il préfère",
  },
];

export const participantsListIntern = [
  {
    id: 'guest-1',
    name: 'Laurent La Rocca',
    email: 'laurent.larocca@gmail.com',
    image: Profile3,
    recommandation: null,
    choice: null,
    locationDeparture: 'Paris',
    financialGain: 'Moyen',
    lastMeeting: '<3 mois>',

    rail: {
      scoreGo: 69,
      scoreStay: 31,
      criterias: [
        {
          label: 'CO2',
          icon: 'co2',
          value: '2.3',
          unit: 'kg',
          score: 5,
          description: "kgCO2e d'émissions de gaz à effet de serre évitées",
        },
        {
          label: 'Coût',
          icon: 'price',
          value: '100',
          unit: '€',
          score: 4,
          description: 'Le montant des dépenses évitées',
        },
        {
          label: 'Durée trajet',
          icon: 'timeTravel',
          value: '4h42',
          score: 3,
          description: '',
        },
        {
          label: 'Lien social',
          icon: 'social',
          value: '1',
          score: 1,
          description: 'Performance de la modalité sur le critère social',
        },
        {
          label: 'Stress',
          icon: 'stress',
          score: 5,
          description: '',
        },
        {
          label: 'Durée séjour',
          icon: 'duration',
          value: '2',
          score: 2,
          description: '',
        },
        {
          label: 'Risque sanitaire',
          icon: 'healthRisk',
          score: 5,
          description: '',
        },
        {
          label: 'Risque sécuritaire',
          icon: 'securityRisk',
          score: 5,
          description: '',
        },
      ],
    },
    flight: {
      scoreGo: 41,
      scoreStay: 59,
      criterias: [
        {
          label: 'CO2',
          icon: 'co2',
          value: '324',
          unit: 'kg',
          score: 1,
          description: "kgCO2e d'émissions de gaz à effet de serre évitées",
        },
        {
          label: 'Coût',
          icon: 'price',
          value: '350',
          unit: '€',
          score: 2,
          description: 'Le montant des dépenses évitées',
        },
        {
          label: 'Durée trajet',
          icon: 'timeTravel',
          value: '4h19',
          score: 3,
          description: '',
        },
        {
          label: 'Lien social',
          icon: 'social',
          value: '1',
          score: 1,
          description: 'Performance de la modalité sur le critère social',
        },
        {
          label: 'Stress',
          icon: 'stress',
          score: 1,
          description: '',
        },
        {
          label: 'Durée séjour',
          icon: 'duration',
          value: '2',
          score: 2,
          description: '',
        },
        {
          label: 'Risque sanitaire',
          icon: 'healthRisk',
          score: 5,
          description: '',
        },
        {
          label: 'Risque sécuritaire',
          icon: 'securityRisk',
          score: 5,
          description: '',
        },
      ],
    },
    car: {
      scoreGo: 51,
      scoreStay: 49,
      criterias: [
        {
          label: 'CO2',
          icon: 'co2',
          value: '256',
          unit: 'kg',
          score: 2,
          description: "kgCO2e d'émissions de gaz à effet de serre évitées",
        },
        {
          label: 'Coût',
          icon: 'price',
          value: '670',
          unit: '€',
          score: 1,
          description: 'Le montant des dépenses évitées',
        },
        {
          label: 'Durée trajet',
          icon: 'timeTravel',
          value: '9h11',
          score: 1,
          description: '',
        },
        {
          label: 'Lien social',
          icon: 'social',
          value: '1',
          score: 1,
          description: 'Performance de la modalité sur le critère social',
        },
        {
          label: 'Stress',
          icon: 'stress',
          score: 3,
          description: '',
        },
        {
          label: 'Durée séjour',
          icon: 'duration',
          value: '2',
          score: 2,
          description: '',
        },
        {
          label: 'Risque sanitaire',
          icon: 'healthRisk',
          score: 5,
          description: '',
        },
        {
          label: 'Risque sécuritaire',
          icon: 'securityRisk',
          score: 5,
          description: '',
        },
      ],
    },
  },
  {
    id: 'guest-2',
    name: 'Pierre-François Verbecque',
    email: 'pierre.francois@gmail.com',
    image: Profile4,
    locationDeparture: 'Strasbourg',
    financialGain: 'Moyen',
    lastMeeting: '3 à 6 mois',
    recommandation: {
      mode: 'rail',
      label: 'Présentiel',
      value: 'presential',
    },
    choice: {
      mode: 'rail',
      label: 'Présentiel',
      value: 'presential',
    },

    rail: {
      scoreGo: 64,
      scoreStay: 36,
      criterias: [
        {
          label: 'CO2',
          icon: 'co2',
          value: '7',
          unit: 'kg',
          score: 0.6,
          description: "kgCO2e d'émissions de gaz à effet de serre évitées",
        },
        {
          label: 'Coût',
          icon: 'price',
          value: '290',
          unit: '€',
          score: 0.8,
          description: 'Le montant des dépenses évitées',
        },
        {
          label: 'Durée trajet',
          icon: 'timeTravel',
          value: '4h42',
          score: 0.3,
          description: '',
        },
        {
          label: 'Lien social',
          icon: 'social',
          value: '2',
          score: 2,
          description: 'Performance de la modalité sur le critère social',
        },
        {
          label: 'Stress',
          icon: 'stress',
          score: 0.2,
          description: '',
        },
        {
          label: 'Durée séjour',
          icon: 'duration',
          value: '2',
          score: 2,
          description: '',
        },
        {
          label: 'Risque sanitaire',
          icon: 'healthRisk',
          score: 5,
          description: '',
        },
        {
          label: 'Risque sécuritaire',
          icon: 'securityRisk',
          score: 5,
          description: '',
        },
      ],
    },
    flight: {
      scoreGo: 43,
      scoreStay: 57,
      criterias: [
        {
          label: 'CO2',
          icon: 'co2',
          value: '321',
          unit: 'kg',
          score: 1,
          description: "kgCO2e d'émissions de gaz à effet de serre évitées",
        },
        {
          label: 'Coût',
          icon: 'price',
          value: '300',
          unit: '€',
          score: 2,
          description: 'Le montant des dépenses évitées',
        },
        {
          label: 'Durée trajet',
          icon: 'timeTravel',
          value: '4h19',
          score: 3,
          description: '',
        },
        {
          label: 'Lien social',
          icon: 'social',
          value: '2',
          score: 2,
          description: 'Performance de la modalité sur le critère social',
        },
        {
          label: 'Stress',
          icon: 'stress',
          score: 1,
          description: '',
        },
        {
          label: 'Durée séjour',
          icon: 'duration',
          value: '2',
          score: 2,
          description: '',
        },
        {
          label: 'Risque sanitaire',
          icon: 'healthRisk',
          score: 5,
          description: '',
        },
        {
          label: 'Risque sécuritaire',
          icon: 'securityRisk',
          score: 5,
          description: '',
        },
      ],
    },
    car: {
      scoreGo: 53,
      scoreStay: 47,
      criterias: [
        {
          label: 'CO2',
          icon: 'co2',
          value: '0', // Remplissez cette valeur avec la donnée correspondante
          unit: 'kg',
          score: 0, // Remplissez cette valeur avec le score correspondant
          description: "kgCO2e d'émissions de gaz à effet de serre évitées",
        },
        {
          label: 'Coût',
          icon: 'price',
          value: '0', // Remplissez cette valeur avec la donnée correspondante
          unit: '€',
          score: 0, // Remplissez cette valeur avec le score correspondant
          description: 'Le montant des dépenses évitées',
        },
        {
          label: 'Durée trajet',
          icon: 'timeTravel',
          value: '0', // Remplissez cette valeur avec la donnée correspondante
          score: 0, // Remplissez cette valeur avec le score correspondant
          description: '',
        },
        {
          label: 'Lien social',
          icon: 'social',
          value: '0', // Remplissez cette valeur avec la donnée correspondante
          score: 0, // Remplissez cette valeur avec le score correspondant
          description: 'Performance de la modalité sur le critère social',
        },
        {
          label: 'Stress',
          icon: 'stress',
          score: 0, // Remplissez cette valeur avec le score correspondant
          description: '',
        },
        {
          label: 'Durée séjour',
          icon: 'duration',
          value: '0', // Remplissez cette valeur avec la donnée correspondante
          score: 0, // Remplissez cette valeur avec le score correspondant
          description: '',
        },
        {
          label: 'Risque sanitaire',
          icon: 'healthRisk',
          score: 0, // Remplissez cette valeur avec le score correspondant
          description: '',
        },
        {
          label: 'Risque sécuritaire',
          icon: 'securityRisk',
          score: 0, // Remplissez cette valeur avec le score correspondant
          description: '',
        },
      ],
    },
  },
  {
    id: 'guest-3',
    name: 'Élisabeth Sery',
    email: 'elisabeth.sery@gmail.com',
    image: Profile2,
    locationDeparture: 'Nantes',
    financialGain: 'Moyen',
    lastMeeting: '>1 an',
    recommandation: {
      mode: 'rail',
      label: 'Présentiel',
      value: 'presential',
    },
    choice: {
      mode: 'flight',
      label: 'Présentiel',
      value: 'presential',
    },

    rail: {
      scoreGo: 67,
      scoreStay: 33,
      criterias: [
        {
          label: 'CO2',
          icon: 'co2',
          value: '5',
          unit: 'kg',
          score: 0.6,
          description: "kgCO2e d'émissions de gaz à effet de serre évitées",
        },
        {
          label: 'Coût',
          icon: 'price',
          value: '280',
          unit: '€',
          score: 0.8,
          description: 'Le montant des dépenses évitées',
        },
        {
          label: 'Durée trajet',
          icon: 'timeTravel',
          value: '4h42',
          score: 0.3,
          description: '',
        },
        {
          label: 'Lien social',
          icon: 'social',
          value: '5',
          score: 5,
          description: 'Performance de la modalité sur le critère social',
        },
        {
          label: 'Stress',
          icon: 'stress',
          score: 0.2,
          description: '',
        },
        {
          label: 'Durée séjour',
          icon: 'duration',
          value: '2',
          score: 2,
          description: '',
        },
        {
          label: 'Risque sanitaire',
          icon: 'healthRisk',
          score: 5,
          description: '',
        },
        {
          label: 'Risque sécuritaire',
          icon: 'securityRisk',
          score: 5,
          description: '',
        },
      ],
    },
    flight: {
      scoreGo: 52,
      scoreStay: 48,
      criterias: [
        {
          label: 'CO2',
          icon: 'co2',
          value: '349',
          unit: 'kg',
          score: 0.6,
          description: "kgCO2e d'émissions de gaz à effet de serre évitées",
        },
        {
          label: 'Coût',
          icon: 'price',
          value: '610',
          unit: '€',
          score: 0.4,
          description: 'Le montant des dépenses évitées',
        },
        {
          label: 'Durée trajet',
          icon: 'timeTravel',
          value: 'N/A', // Remplissez cette valeur avec la donnée correspondante
          score: 0, // Remplissez cette valeur avec le score correspondant
          description: '',
        },
        {
          label: 'Lien social',
          icon: 'social',
          value: '5',
          score: 5,
          description: 'Performance de la modalité sur le critère social',
        },
        {
          label: 'Stress',
          icon: 'stress',
          score: 0, // Remplissez cette valeur avec le score correspondant
          description: '',
        },
        {
          label: 'Durée séjour',
          icon: 'duration',
          value: 'N/A', // Remplissez cette valeur avec la donnée correspondante
          score: 0, // Remplissez cette valeur avec le score correspondant
          description: '',
        },
        {
          label: 'Risque sanitaire',
          icon: 'healthRisk',
          score: 5,
          description: '',
        },
        {
          label: 'Risque sécuritaire',
          icon: 'securityRisk',
          score: 5,
          description: '',
        },
      ],
    },
    car: {
      scoreGo: 56,
      scoreStay: 44,
      criterias: [
        {
          label: 'CO2',
          icon: 'co2',
          value: '0', // Remplissez cette valeur avec la donnée correspondante
          unit: 'kg',
          score: 0, // Remplissez cette valeur avec le score correspondant
          description: "kgCO2e d'émissions de gaz à effet de serre évitées",
        },
        {
          label: 'Coût',
          icon: 'price',
          value: '0', // Remplissez cette valeur avec la donnée correspondante
          unit: '€',
          score: 0, // Remplissez cette valeur avec le score correspondant
          description: 'Le montant des dépenses évitées',
        },
        {
          label: 'Durée trajet',
          icon: 'timeTravel',
          value: '0', // Remplissez cette valeur avec la donnée correspondante
          score: 0, // Remplissez cette valeur avec le score correspondant
          description: '',
        },
        {
          label: 'Lien social',
          icon: 'social',
          value: '0', // Remplissez cette valeur avec la donnée correspondante
          score: 0, // Remplissez cette valeur avec le score correspondant
          description: 'Performance de la modalité sur le critère social',
        },
        {
          label: 'Stress',
          icon: 'stress',
          score: 0, // Remplissez cette valeur avec le score correspondant
          description: '',
        },
        {
          label: 'Durée séjour',
          icon: 'duration',
          value: '0', // Remplissez cette valeur avec la donnée correspondante
          score: 0, // Remplissez cette valeur avec le score correspondant
          description: '',
        },
        {
          label: 'Risque sanitaire',
          icon: 'healthRisk',
          score: 0, // Remplissez cette valeur avec le score correspondant
          description: '',
        },
        {
          label: 'Risque sécuritaire',
          icon: 'securityRisk',
          score: 0, // Remplissez cette valeur avec le score correspondant
          description: '',
        },
      ],
    },
  },

  {
    id: 'guest-4',
    name: 'Myriam Cholet',
    email: 'myriam.cholet@gmail.com',
    image: Profile1,
    locationDeparture: 'Lille',
    financialGain: 'Moyen',
    lastMeeting: '6 mois à 1 an',
    recommandation: {
      mode: 'rail',
      label: 'Présentiel',
      value: 'presential',
    },
    choice: {
      mode: '',
      label: 'Distanciel',
      value: 'remote',
    },

    rail: {
      scoreGo: 65,
      scoreStay: 35,
      criterias: [
        {
          label: 'CO2',
          icon: 'co2',
          value: '6',
          unit: 'kg',
          score: 0.6,
          description: "kgCO2e d'émissions de gaz à effet de serre évitées",
        },
        {
          label: 'Coût',
          icon: 'price',
          value: '190',
          unit: '€',
          score: 0.8,
          description: 'Le montant des dépenses évitées',
        },
        {
          label: 'Durée trajet',
          icon: 'timeTravel',
          value: '4h42',
          score: 0.3,
          description: '',
        },
        {
          label: 'Lien social',
          icon: 'social',
          value: '4',
          score: 4,
          description: 'Performance de la modalité sur le critère social',
        },
        {
          label: 'Stress',
          icon: 'stress',
          score: 0.2,
          description: '',
        },
        {
          label: 'Durée séjour',
          icon: 'duration',
          value: '2',
          score: 2,
          description: '',
        },
        {
          label: 'Risque sanitaire',
          icon: 'healthRisk',
          score: 5,
          description: '',
        },
        {
          label: 'Risque sécuritaire',
          icon: 'securityRisk',
          score: 5,
          description: '',
        },
      ],
    },
    flight: {
      scoreGo: 46,
      scoreStay: 54,
      criterias: [
        {
          label: 'CO2',
          icon: 'co2',
          value: '0', // Remplissez cette valeur avec la donnée correspondante
          unit: 'kg',
          score: 0, // Remplissez cette valeur avec le score correspondant
          description: "kgCO2e d'émissions de gaz à effet de serre évitées",
        },
        {
          label: 'Coût',
          icon: 'price',
          value: '0', // Remplissez cette valeur avec la donnée correspondante
          unit: '€',
          score: 0, // Remplissez cette valeur avec le score correspondant
          description: 'Le montant des dépenses évitées',
        },
        {
          label: 'Durée trajet',
          icon: 'timeTravel',
          value: '0', // Remplissez cette valeur avec la donnée correspondante
          score: 0, // Remplissez cette valeur avec le score correspondant
          description: '',
        },
        {
          label: 'Lien social',
          icon: 'social',
          value: '0', // Remplissez cette valeur avec la donnée correspondante
          score: 0, // Remplissez cette valeur avec le score correspondant
          description: 'Performance de la modalité sur le critère social',
        },
        {
          label: 'Stress',
          icon: 'stress',
          score: 0, // Remplissez cette valeur avec le score correspondant
          description: '',
        },
        {
          label: 'Durée séjour',
          icon: 'duration',
          value: '0', // Remplissez cette valeur avec la donnée correspondante
          score: 0, // Remplissez cette valeur avec le score correspondant
          description: '',
        },
        {
          label: 'Risque sanitaire',
          icon: 'healthRisk',
          score: 0, // Remplissez cette valeur avec le score correspondant
          description: '',
        },
        {
          label: 'Risque sécuritaire',
          icon: 'securityRisk',
          score: 0, // Remplissez cette valeur avec le score correspondant
          description: '',
        },
      ],
    },
    car: {
      scoreGo: 54,
      scoreStay: 46,
      criterias: [
        {
          label: 'CO2',
          icon: 'co2',
          value: '0', // Remplissez cette valeur avec la donnée correspondante
          unit: 'kg',
          score: 0, // Remplissez cette valeur avec le score correspondant
          description: "kgCO2e d'émissions de gaz à effet de serre évitées",
        },
        {
          label: 'Coût',
          icon: 'price',
          value: '0', // Remplissez cette valeur avec la donnée correspondante
          unit: '€',
          score: 0, // Remplissez cette valeur avec le score correspondant
          description: 'Le montant des dépenses évitées',
        },
        {
          label: 'Durée trajet',
          icon: 'timeTravel',
          value: '0', // Remplissez cette valeur avec la donnée correspondante
          score: 0, // Remplissez cette valeur avec le score correspondant
          description: '',
        },
        {
          label: 'Lien social',
          icon: 'social',
          value: '0', // Remplissez cette valeur avec la donnée correspondante
          score: 0, // Remplissez cette valeur avec le score correspondant
          description: 'Performance de la modalité sur le critère social',
        },
        {
          label: 'Stress',
          icon: 'stress',
          score: 0, // Remplissez cette valeur avec le score correspondant
          description: '',
        },
        {
          label: 'Durée séjour',
          icon: 'duration',
          value: '0', // Remplissez cette valeur avec la donnée correspondante
          score: 0, // Remplissez cette valeur avec le score correspondant
          description: '',
        },
        {
          label: 'Risque sanitaire',
          icon: 'healthRisk',
          score: 0, // Remplissez cette valeur avec le score correspondant
          description: '',
        },
        {
          label: 'Risque sécuritaire',
          icon: 'securityRisk',
          score: 0, // Remplissez cette valeur avec le score correspondant
          description: '',
        },
      ],
    },
  },
];
