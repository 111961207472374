import { ReactComponent as CalendarAddIcon } from '@assets/icons/calendar-add.svg';
// import { ReactComponent as OutlookLogo } from '@assets/icons/outlook-logo.svg';
import { Button, Heading, Text } from '@chakra-ui/react';
import { colors } from '@theme/colors';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export default function EventsChoiceCreation() {
  return (
    <>
      <Heading size="xl" textAlign="center" my="32px">
        Organisons un évènement
      </Heading>
      <CardsWrapper>
        <CardNewEvent>
          <CardIcon>
            <CalendarAddIcon />
          </CardIcon>
          <Heading size="md" mt="24px" mb="12px">
            Créer un évènement
          </Heading>
          <Text color="black60" fontSize="md" mb="20px">
            Créer un évènement à partir de zéro
          </Text>
          <Link to="/create-event">
            <Button>Créer votre premier évènement</Button>
          </Link>
        </CardNewEvent>

        {/* <CardNewEvent>
          <CardIcon>
            <OutlookLogo />
          </CardIcon>
          <Heading size="md" mt="24px" mb="12px">
            Extension Outlook
          </Heading>
          <Text color="black60" fontSize="md" mb="30px" textAlign="center">
            Importer un évènement depuis Outlook
          </Text>
          <Button variant="secondary">Installer l&apos;extension Outlook</Button>
        </CardNewEvent> */}
      </CardsWrapper>
    </>
  );
}

const CardsWrapper = styled.div`
  /* display: grid;
  grid-template-columns: auto auto;
  grid-gap: 20px;
  align-items: stretch; */
`;
const CardIcon = styled.div`
  background: ${colors.primary10};
  border-radius: 48px;
  padding: 16px;
`;
const CardNewEvent = styled.div`
  padding: 32px 16px;
  background-color: ${colors.white};
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;
