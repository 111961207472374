import { LoginFlow, UpdateLoginFlowBody } from '@ory/client';
import { UserAuthCard } from '@ory/elements';
import { sdk, sdkError } from '@providers/ory/sdk';
import { routes } from '@utils/consts';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import LoaderAccount from './LoaderAccount';
import * as S from './StyledAccount';

export const LoginCard = (): JSX.Element => {
  const [flow, setFlow] = useState<LoginFlow | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const aal2 = searchParams.get('aal2');
  const loginChallenge = searchParams.get('login_challenge');
  const returnTo = import.meta.env.VITE_APP_URL;
  const navigate = useNavigate();

  const getFlow = useCallback(
    (flowId: string) =>
      sdk
        .getLoginFlow({ id: flowId })
        .then(({ data: flow }) => setFlow(flow))
        .catch(sdkErrorHandler),
    [],
  );

  const sdkErrorHandler = sdkError(getFlow, setFlow, routes.LOGIN, true);

  const createFlow = () => {
    sdk
      .createBrowserLoginFlow({
        refresh: true,
        aal: aal2 ? 'aal2' : 'aal1',
        ...(loginChallenge && { loginChallenge: loginChallenge }),
        ...(returnTo && { returnTo: returnTo }),
      })

      .then(({ data: flow }) => {
        setSearchParams({ ['flow']: flow.id });
        setFlow(flow);
      })
      .catch(sdkErrorHandler);
  };

  const submitFlow = (body: UpdateLoginFlowBody) => {
    if (!flow) return navigate(routes.LOGIN, { replace: true });

    sdk
      .updateLoginFlow({ flow: flow.id, updateLoginFlowBody: body })
      .then(({ data: updateLoginFlow }) => {
        console.log('updateLoginFlow: ', updateLoginFlow);
        navigate(routes.HOME, { replace: true });
      })
      .catch(sdkErrorHandler);
  };

  useEffect(() => {
    const flowId = searchParams.get('flow');

    if (flowId) {
      getFlow(flowId).catch(createFlow);
      return;
    }

    createFlow();
  }, []);

  return flow ? (
    <S.AccountCard className="accountCard">
      <UserAuthCard
        flowType="login"
        className="authCard"
        flow={flow}
        additionalProps={{
          forgotPasswordURL: {
            handler: () => {
              const search = new URLSearchParams();
              flow.return_to && search.set('return_to', flow.return_to);
              navigate(
                {
                  pathname: routes.RECOVERY,
                  search: search.toString(),
                },
                { replace: true },
              );
            },
          },
          signupURL: {
            handler: () => {
              const search = new URLSearchParams();
              flow.return_to && search.set('return_to', flow.return_to);
              flow.oauth2_login_challenge &&
                search.set('login_challenge', flow.oauth2_login_challenge);
              navigate(
                {
                  pathname: routes.REGISTRATION,
                  search: search.toString(),
                },
                { replace: true },
              );
            },
          },
        }}
        includeScripts={true}
        onSubmit={({ body }) => submitFlow(body as UpdateLoginFlowBody)}
      />
    </S.AccountCard>
  ) : (
    <LoaderAccount />
  );
};
