import { Box, Heading, Text } from '@chakra-ui/react';
import styled from 'styled-components';

type UserSummaryProps = {
  name: string;
  subText: string;
  status?: string;
  pictureURL?: string;
};

export default function UserSummary({
  name,
  subText,
  status,
  pictureURL,
}: UserSummaryProps) {
  return (
    <EventCreator>
      {/* <PhotoIcon /> */}
      {pictureURL && <ProfilePicture src={pictureURL} alt={name} />}
      <Box ml="12px">
        <Heading size="sm">{name}</Heading>
        <Text fontSize="xs" color="black60">
          {subText}
        </Text>
      </Box>
      {status && (
        <Text fontSize="xs" color="black40" ml="12px">
          {status}
        </Text>
      )}
    </EventCreator>
  );
}

const EventCreator = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

const ProfilePicture = styled.img`
  max-width: 32px;
  max-height: 32px;
`;
