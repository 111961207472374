import { Button, Heading, Skeleton, Spinner } from '@chakra-ui/react';
import FormElement from '@components/FormElement';
import PanelEventInfos from '@features/assessment/PanelEventInfos';
import { yupResolver } from '@hookform/resolvers/yup';
import { api } from '@utils//api';
import { Assessment, Event } from '@utils/types';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import * as yup from 'yup';

import ChoiceTraveler from './ChoiceTraveler';
import Recommandations from './Recommandations';

export default function AssessmentTraveler() {
  const { id: eventId } = useParams();
  const [event, setEvent] = useState(null);
  const [results, setResults] = useState<Assessment[] | null>(null);
  const [linksResults, setLinksResults] = useState<Assessment[] | null>(null);
  const [assessmentForm, setAssessmentForm] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);

  const schema = yup.object().shape({
    locationDeparture: yup.object().required("Choisir le lieu de l'évènement").nullable(),
  });
  const [schemaValidation, setSchemaValidation] = useState(schema);

  useEffect(() => {
    api
      .get(`/events/${eventId}/assessmentForm`)
      .then((data: any) => {
        data.formElements.map((element: any) => setValue(element.name, element.value));
        setAssessmentForm(data);
        updateSchema(data);
        handleSubmit(onSubmit)();
      })
      .catch((error) => {
        console.error("Une erreur s'est produite :", error);
      });

    api.get(`/events/`).then((data: any) => {
      const currentEvent = data.events.filter((event: Event) => event.id === eventId)[0];
      setEvent(currentEvent);
    });
  }, []);

  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      locationDeparture: null,
      meetingsNumber: null,
      financialGain: null,
      lastPhysicalMeeting: 'less-3-months',
    },
    resolver: yupResolver(schemaValidation),
  });

  const hasElement = (data: any, name: string) =>
    data?.formElements.some((element: any) => element.name === name);

  const updateSchema = (data: any) => {
    if (hasElement(data, 'meetingsNumber')) {
      const schemaMeetings = yup.object().shape({
        meetingsNumber: yup
          .number()
          .typeError('Le nombre de réunions est requis')
          .required(),
      });
      setSchemaValidation(schemaValidation.concat(schemaMeetings));
    }
  };

  const getResults = (values: any) => {
    const payload = {
      parameters: {
        locationDeparture: {
          ...values.locationDeparture,
        },
        lastPhysicalMeeting: values.lastPhysicalMeeting,

        ...(values.meetingsNumber && {
          meetingsNumber: values.meetingsNumber.toString(),
        }),
        ...(values.financialGain && {
          financialGain: values.financialGain.toString(),
        }),
      },
    };
    setIsLoading(true);

    api
      .url(`/events/${eventId}/assessments/`)
      .post(payload)
      .then((result: any) => {
        setResults(result.assessments);
        setLinksResults(result.links);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onSubmit = (values: any) => {
    getResults(values);
  };

  return (
    <Wrapper>
      <SidePanel>{event && <PanelEventInfos event={event} />}</SidePanel>

      <MainContent>
        <Heading size="md" color="black" mb="12px">
          Vos informations
        </Heading>
        <Form onSubmit={handleSubmit(onSubmit)}>
          {assessmentForm ? (
            assessmentForm.formElements.map((element: any) => (
              <InfosChoice key={element.label}>
                <FormElement
                  control={control}
                  errors={errors}
                  register={register}
                  element={element}
                  value={element.value}
                />
              </InfosChoice>
            ))
          ) : (
            <>
              {Array.from({ length: 3 }).map((_, index) => (
                <SkeletonFormCol key={index}>
                  <Skeleton
                    marginBottom="8px"
                    width="60%"
                    borderRadius="4px"
                    height="15px"
                  />
                  <Skeleton height="46px" />
                </SkeletonFormCol>
              ))}
            </>
          )}

          <Button type="submit" mt="24px" mr="12px">
            Calculer
          </Button>
        </Form>
        {isLoading && (
          <SpinnerWrapper>
            <Heading size="md" color="primary" mb="16px">
              Calcul en cours...
            </Heading>
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="primary"
              size="xl"
            />
          </SpinnerWrapper>
        )}
        {results && (
          <>
            <Recommandations results={results} />
            <PopoverWrapper>
              {assessmentForm && <ChoiceTraveler links={linksResults} />}
            </PopoverWrapper>
          </>
        )}
      </MainContent>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
`;

const MainContent = styled.div`
  padding: 0 20px;
  flex: 1;
  position: relative;
  margin-bottom: 200px;
`;
const SidePanel = styled.div`
  width: 400px;
  padding: 0 20px;
`;

const InfosChoice = styled.div`
  flex: 1;
  padding: 12px 20px;
`;

const SkeletonFormCol = styled.div`
  flex: 1;
  width: 100%;
  padding: 12px 20px;
`;
const Form = styled.form`
  border-radius: 16px;
  background: white;
  display: flex;
  width: 100%;
  margin-bottom: 36px;
`;
const PopoverWrapper = styled.div`
  position: fixed;
  bottom: 20px;
  right: 16px;
  left: 376px;
`;
const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 42px;
`;
