import { SettingsFlow, UpdateSettingsFlowBody } from '@ory/client';
import {
  gridStyle,
  NodeMessages,
  UserSettingsCard,
  UserSettingsFlowType,
} from '@ory/elements';
import { sdk, sdkError } from '@providers/ory/sdk';
import { routes } from '@utils/consts';
import { translateFlow } from '@utils/translateFlow';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import LoaderAccount from './LoaderAccount';
import * as S from './StyledAccount';

export const SettingsCard = () => {
  const [flow, setFlow] = useState<SettingsFlow | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const getFlow = useCallback(
    (flowId: string) =>
      sdk
        .getSettingsFlow({ id: flowId })
        .then(({ data: flow }) => setFlow(translateFlow(flow)))
        .catch(sdkErrorHandler),
    [],
  );

  const sdkErrorHandler = sdkError(getFlow, setFlow, routes.SETTINGS, true);

  const createFlow = () => {
    sdk
      .createBrowserSettingsFlow()
      .then(({ data: flow }) => {
        setSearchParams({ ['flow']: flow.id });
        setFlow(translateFlow(flow));
      })
      .catch(sdkErrorHandler);
  };

  // submit any of the settings form data to Ory
  const onSubmit = (body: UpdateSettingsFlowBody) => {
    if (!flow) return navigate(routes.SETTINGS, { replace: true });

    sdk
      .updateSettingsFlow({ flow: flow.id, updateSettingsFlowBody: body })
      .then(({ data: flow }) => {
        setFlow(flow);
      })
      .catch(sdkErrorHandler);
  };

  useEffect(() => {
    const flowId = searchParams.get('flow');
    if (flowId) {
      getFlow(flowId).catch(createFlow);
      return;
    }
    createFlow();
  }, []);

  return flow ? (
    <S.AccountCard>
      <div className={`${gridStyle({ gap: 16 })} settingCard`}>
        <NodeMessages uiMessages={flow.ui.messages} />

        {(
          [
            'profile',
            'password',
            'totp',
            'webauthn',
            'lookup_secret',
            'oidc',
          ] as UserSettingsFlowType[]
        ).map((flowType: UserSettingsFlowType, index) => (
          <UserSettingsCard
            key={index}
            className="authCard"
            flow={flow}
            method={flowType}
            includeScripts={true}
            onSubmit={({ body }: any) => onSubmit(body)}
          />
        ))}
      </div>
    </S.AccountCard>
  ) : (
    <LoaderAccount />
  );
};
