import { ReactComponent as CalendarIcon } from '@assets/icons/calendar-black.svg';
import { Box, Input, useDisclosure, useOutsideClick } from '@chakra-ui/react';
import {
  InputGroup,
  InputLeftElement,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import { colors } from '@theme/colors';
import {
  Calendar,
  CalendarControls,
  CalendarDate,
  CalendarDays,
  CalendarMonth,
  CalendarMonthName,
  CalendarMonths,
  CalendarNextButton,
  CalendarPrevButton,
  CalendarWeek,
} from '@uselessdev/datepicker';
import { format, isValid, parse } from 'date-fns';
import { fr } from 'date-fns/locale';
import React from 'react';
import styled from 'styled-components';

type DatepickerProps = {
  onDateSelect: (date: CalendarDate) => void;
  invalid?: boolean;
};

export default function Datepicker({ onDateSelect, invalid }: DatepickerProps) {
  const [date, setDate] = React.useState<CalendarDate>();
  const [value, setValue] = React.useState('');
  const dateFormat = 'dd/MM/yyyy';
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef(null);
  const calendarRef = React.useRef(null);

  const handleSelectDate = (date: any) => {
    setDate(date);
    setValue(() => (isValid(date) ? format(date, dateFormat) : ''));
    onDateSelect(date);
    onClose();
  };

  const match = (value: string) => value.match(/(\d{2})\/(\d{2})\/(\d{4})/);

  const handleInputChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const parsedDate = parse(target.value, dateFormat, new Date());
    if (isValid(parsedDate)) {
      setValue(target.value);
      onDateSelect(parsedDate);
    }
    match(target.value) && onClose();
  };

  useOutsideClick({
    ref: calendarRef,
    handler: onClose,
    enabled: isOpen,
  });

  React.useEffect(() => {
    if (match(value)) {
      const date = parse(value, dateFormat, new Date());
      return setDate(date);
    }
  }, [value]);

  return (
    <Box minH="20px">
      <Popover
        placement="auto-start"
        isOpen={isOpen}
        onClose={onClose}
        initialFocusRef={initialRef}
        isLazy>
        <PopoverTrigger>
          <Box onClick={onOpen} ref={initialRef}>
            <InputGroup>
              <InputLeftElement pointerEvents="none" ml="8px" mt="4px">
                <StyledCalendarIcon />
              </InputLeftElement>
              <Input
                placeholder="jj/mm/aaaa"
                pl="52px"
                height="48px"
                value={value}
                isInvalid={invalid}
                onChange={handleInputChange}
                background={colors.grey}
              />
            </InputGroup>
          </Box>
        </PopoverTrigger>

        <PopoverContent
          p={0}
          w="min-content"
          border="none"
          outline="none"
          _focus={{ boxShadow: 'none' }}
          ref={calendarRef}>
          <Calendar
            value={{ start: date }}
            locale={fr}
            onSelectDate={handleSelectDate}
            singleDateSelection>
            <PopoverBody p={0}>
              <CalendarControls>
                <CalendarPrevButton />
                <CalendarNextButton />
              </CalendarControls>

              <CalendarMonths>
                <CalendarMonth>
                  <CalendarMonthName />
                  <CalendarWeek />
                  <CalendarDays />
                </CalendarMonth>
              </CalendarMonths>
            </PopoverBody>
          </Calendar>
        </PopoverContent>
      </Popover>
    </Box>
  );
}

const StyledCalendarIcon = styled(CalendarIcon)`
  position: relative;
  bottom: 4px;
  left: -4px;
`;
