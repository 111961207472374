import { FormControl, FormLabel, Input, Select, Text } from '@chakra-ui/react';
import { Control, Controller } from 'react-hook-form';

import AutoCompleteLocation from './AutoCompleteLocation';

type FormElementProps = {
  control: Control<any>;
  errors: any;
  register?: any;
  element: {
    label: string;
    name: string;
    options?: { label: string; value: string }[];
    type: string;
    placeholder?: string;
    value?: string | null;
  };
  value?: any;
};
const FormElement = ({ control, errors, register, element, value }: FormElementProps) => {
  switch (element.type) {
    case 'text':
      return (
        <FormControl isInvalid={!!errors[element.name]}>
          <FormLabel htmlFor={element.name}>{element.label}</FormLabel>
          <Input
            id={element.name}
            placeholder={`Entrez le ${element.label.toLowerCase()}`}
            {...register(element.name)}
          />

          {errors[element.name] && (
            <Text variant="error">{errors[element.name]?.message} </Text>
          )}
        </FormControl>
      );
    case 'number':
      return (
        <FormControl isInvalid={!!errors[element.name]}>
          <FormLabel htmlFor={element.name}>{element.label}</FormLabel>
          <Input
            type="number"
            id={element.name}
            placeholder={`Entrez le ${element.label.toLowerCase()}`}
            {...register(element.name)}
          />

          {errors[element.name] && (
            <Text variant="error">{errors[element.name]?.message} </Text>
          )}
        </FormControl>
      );
    case 'place':
      return (
        <FormControl isInvalid={!!errors[element.name]}>
          <FormLabel htmlFor={element.name}>{element.label}</FormLabel>
          <Controller
            name={element.name}
            control={control}
            render={({ field: { onChange } }) => (
              <AutoCompleteLocation
                type="city"
                defaultSearchText={value && value.Name}
                onLocationSelected={(location) => {
                  onChange(location);
                }}
              />
            )}
          />
          {errors[element.name] && (
            <Text variant="error">{errors[element.name]?.message} </Text>
          )}
        </FormControl>
      );
    case 'select':
      return (
        <FormControl isInvalid={!!errors[element.name]}>
          <FormLabel htmlFor={element.name}>{element.label}</FormLabel>
          <Controller
            name={element.name}
            control={control}
            render={({ field }) => (
              <Select
                {...(register && register(element.name))}
                {...field}
                placeholder={element.placeholder}>
                {element.options?.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Select>
            )}
          />
          {errors[element.name] && (
            <Text variant="error">{errors[element.name]?.message} </Text>
          )}
        </FormControl>
      );
    default:
      return null;
  }
};

export default FormElement;
