import styled from 'styled-components';

type AvatarLetterProps = {
  text: string;
  backgroundColor: string;
};

const AvatarLetter = ({ text, backgroundColor }: AvatarLetterProps) => {
  const firstLetter = text[0].toUpperCase();
  return (
    <AvatarLetterContainer backgroundColor={backgroundColor}>
      {firstLetter}
    </AvatarLetterContainer>
  );
};

export default AvatarLetter;

const AvatarLetterContainer = styled.div`
  background-color: ${({ backgroundColor }: { backgroundColor: string }) =>
    backgroundColor};
  color: white;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  flex-shrink: 0;
`;
