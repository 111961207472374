import { Box, useRadio } from '@chakra-ui/react';
import { colors } from '@theme/colors';
import styled from 'styled-components';

export default function RadioCard(props: any) {
  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Box position="relative" as="label">
      <input {...input} />
      {props.isDisabled && <BannerSoon>Prochainement...</BannerSoon>}
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="16px"
        borderColor="black20"
        _checked={{
          bg: 'primary10',
          borderColor: 'primary',
        }}
        padding="16px">
        {props.children}
      </Box>
    </Box>
  );
}

const BannerSoon = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background-color: ${colors.primary50};
  color: ${colors.primary};
  padding: 4px 8px;
  border-radius: 0px 16px 0px 0px;
  font-size: 12px;
  font-weight: bold;
  z-index: 1;
`;
