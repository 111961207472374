import { Box, Heading, Text } from '@chakra-ui/react';
import { colors } from '@theme/colors';
import { Assessment } from '@utils/types';
import styled from 'styled-components';

type PercentAssessmentProps = {
  result: Assessment;
};

export default function PercentAssessment({ result }: PercentAssessmentProps) {
  const percentGo = Math.floor(result.goScore * 100);
  const percentStay = 100 - percentGo;

  const { recommandation } = result;
  const colorReco = recommandation === 'Stay' ? colors.primary : colors.blue;
  const colorNotReco = recommandation === 'Stay' ? colors.blue : colors.primary;

  const percentReco = recommandation === 'Stay' ? percentStay : percentGo;
  const percentNotReco = recommandation === 'Stay' ? percentGo : percentStay;

  return (
    <Wrapper>
      <Heading size="xl" pr="20px" fontWeight="normal" color={colorReco}>
        {result.recommandation}
      </Heading>
      <PercentageSection>
        <Percentage>
          <PercentReco color={colorReco}>{percentReco}%</PercentReco>
          <BarPercent>
            <BarReco
              color={colorReco}
              reco={recommandation}
              value={percentReco}></BarReco>
            <BarNotReco
              color={colorNotReco}
              reco={recommandation}
              value={percentNotReco}></BarNotReco>
          </BarPercent>
          <PercentNotReco color={colorNotReco}>{percentNotReco}%</PercentNotReco>
        </Percentage>
        <Legends reco={recommandation}>
          <Box display="flex">
            <BulletStay></BulletStay>
            <Text fontSize="xs" color="primary" mr="4px">
              Stay:{' '}
            </Text>
            <Text fontSize="xs" color="black80">
              Meeting en distanciel recommandé
            </Text>
          </Box>
          <Box display="flex">
            <BulletGo></BulletGo>
            <Text fontSize="xs" color="primary" mr="4px">
              Go:
            </Text>
            <Text fontSize="xs" color="black80">
              Meeting en présentiel recommandé
            </Text>
          </Box>
        </Legends>
      </PercentageSection>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  border-radius: 16px;
  background: white;
  padding: 16px;
  display: flex;
`;

const PercentageSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const Percentage = styled.div`
  display: flex;
  align-items: center;
`;

const PercentReco = styled.div`
  font-size: 16px;
  margin-right: 8px;
  font-weight: bold;
  color: ${(props) => props.color};
`;
const PercentNotReco = styled(PercentReco)`
  margin: 0 0 0 8px;
`;
const BarPercent = styled.div`
  height: 24px;
  display: flex;
  flex: 1;
  border-radius: 12px;
  overflow: hidden;
`;
const BarReco = styled.div<{ reco: string; value: number }>`
  background: ${(props) => props.color};
  margin-right: 4px;
  width: ${(props) => props.value + '%'};
  order: ${(props) => (props.reco === 'Stay' ? 1 : 2)};
`;
const BarNotReco = styled(BarReco)`
  margin-right: 0;
`;

const Legends = styled.div<{ reco: string }>`
  display: flex;
  margin-top: 8px;
  justify-content: space-between;
  flex-direction: ${(props) => (props.reco === 'Stay' ? 'row' : 'row-reverse')};
`;

const BulletStay = styled.div`
  background: ${colors.primary};
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 8px;
  flex-shrink: 0;
`;
const BulletGo = styled(BulletStay)`
  background: ${colors.blue};
`;
