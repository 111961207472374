import { ReactComponent as Tick } from '@assets/icons/tick.svg';
import styled from 'styled-components';

type TickProps = {
  color: string;
};

export default function TickIcon({ color, ...styles }: TickProps) {
  return (
    <TickWrapper {...styles} style={{ backgroundColor: color }}>
      <Tick />
    </TickWrapper>
  );
}

const TickWrapper = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid white;
  border-radius: 100%;
`;
