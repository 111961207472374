import { Box, Heading, Text } from '@chakra-ui/react';
import { colors } from '@theme/colors';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

type ParticipantsChoiceProps = {
  type: string;
};

export default function ParticipantsChoice({ type }: ParticipantsChoiceProps) {
  const [values, setValues] = useState<{
    remote: number;
    presential: number;
    acceptability: number;
  } | null>(null);

  useEffect(() => {
    if (type === 'presential') {
      setValues({ remote: 0, presential: 100, acceptability: 75 });
    }
    if (type === 'remote') {
      setValues({ remote: 100, presential: 0, acceptability: 25 });
    }
  }, [type]);

  return (
    values && (
      <Wrapper>
        <Section>
          <Text fontSize="sm" fontWeight="bold" color="black60" textTransform="uppercase">
            {`Participation en distanciel`}
          </Text>
          <Heading size="lg" color="black" textTransform="uppercase">
            {values.remote}%
          </Heading>
        </Section>
        <Section>
          <Text fontSize="sm" fontWeight="bold" color="black60" textTransform="uppercase">
            {`Participation en présentiel`}
          </Text>
          <Heading size="lg" color="black" textTransform="uppercase">
            {values.presential}%
          </Heading>
        </Section>
        <Section>
          <Box display="flex" alignItems="center">
            <Text
              fontSize="sm"
              fontWeight="bold"
              color="black60"
              textTransform="uppercase">
              {`Taux d'acceptabilité`}
            </Text>
          </Box>
          <Box display="flex" alignItems="center">
            <Heading size="lg" color="black" textTransform="uppercase" mr="8px">
              {values.acceptability}%
            </Heading>
            <Text fontSize="xs" color="black60">
              {`des participants sont d'accord avec cette modalité`}
            </Text>
          </Box>
        </Section>
      </Wrapper>
    )
  );
}

const Wrapper = styled.div`
  border-radius: 16px;
  background: ${colors.white};
  padding: 16px;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 16px;
  min-height: 130px;
`;
const Section = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
