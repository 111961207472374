import { Box, Button, Heading, Text, useRadioGroup } from '@chakra-ui/react';
import RadioButton from '@components/RadioButton';
import { colors } from '@theme/colors';
import { useState } from 'react';
import styled from 'styled-components';

type Choice = {
  value: string;
  label: string;
  description: string;
};

export default function ChoiceTraveler() {
  const [choiceOrganiser, setChoiceOrganiser] = useState<Choice | null>(null);
  const choices: Choice[] = [
    {
      value: 'stay',
      label: 'Distanciel',
      description: "Je choisis d'organiser mon événement en distanciel",
    },
    {
      value: 'go',
      label: 'Présentiel',
      description: "Je choisis d'organiser mon événement en présentiel",
    },
    {
      value: 'hybrid',
      label: 'Hybride',
      description: "Je choisis d'organiser mon événement en hybride",
    },
  ];

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'choice',

    onChange: (value) => {
      const choice = choices.find((choice) => choice.value === value) || null;
      setChoiceOrganiser(choice);
    },
  });
  return (
    <Wrapper>
      <Description>
        <Heading size="md" color="white" mb="4px">
          Donnez votre décision finale
        </Heading>
        <Text fontSize="sm" color="white">
          {choiceOrganiser
            ? choiceOrganiser.description
            : 'Quelle modalité choisissez-vous pour votre événement ?'}
        </Text>
      </Description>

      <Choice {...getRootProps()}>
        {choices.map((choice) => (
          <RadioButton
            className="opacity10"
            key={choice.value}
            {...getRadioProps({ value: choice.value })}>
            {choice.label}
          </RadioButton>
        ))}
      </Choice>
      <Box p="0 16px 16px 16px">
        <Button width="100%" isDisabled={!choiceOrganiser} variant="tertiary">
          Valider
        </Button>
      </Box>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background: ${colors.primary};
  border-radius: 16px;
`;
const Description = styled.div`
  padding: 16px;
  border-radius: 16px 16px 0 0;
  background: ${colors.primary200};
`;
const Choice = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;
  label {
    flex: 1;
    .opacity10 {
      justify-content: center;
    }
  }
`;
