import { ReactComponent as CarIcon } from '@assets/icons/car.svg';
import { ReactComponent as PlaneIcon } from '@assets/icons/plane.svg';
import { ReactComponent as TrainIcon } from '@assets/icons/train.svg';
import { Heading, useRadioGroup } from '@chakra-ui/react';
import RadioButton from '@components/RadioButton';
import BoxInfosCalcul from '@features/assessment/assessment-traveler/BoxInfosCalcul';
import PercentAssessment from '@features/assessment/assessment-traveler/PercentAssessment';
import CardCriteria from '@features/assessment/CardCriteria';
import { Assessment } from '@utils/types';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

type RecommandationsProps = {
  results: Assessment[];
};

export default function Recommandations({ results }: RecommandationsProps) {
  const [result, setResult] = useState<Assessment | null>(null);

  useEffect(() => {
    setResult(results[0]);
  }, [results]);

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'transport',
    defaultValue: 'rail',
    onChange: (value) => {
      const currentResult = results.filter((res) => res.transport === value)[0];
      setResult(currentResult);
    },
  });

  const transportMappings: any = {
    rail: { label: 'Train', icon: <TrainIcon /> },
    flight: { label: 'Avion', icon: <PlaneIcon /> },
    car: { label: 'Voiture', icon: <CarIcon /> },
  };

  const translate = (transport: any) => transportMappings[transport]?.label || '';
  const getIcon = (transport: any) => transportMappings[transport]?.icon || null;

  return (
    <Wrapper>
      <Heading size="md" color="black" mb="12px">
        Notre recommandation
      </Heading>

      <TransportGroup {...getRootProps()}>
        {results.map((result) => (
          <RadioButton
            key={result.transport}
            className="transportMode"
            {...getRadioProps({ value: result.transport })}>
            {getIcon(result.transport)}
            {translate(result.transport)}
          </RadioButton>
        ))}
      </TransportGroup>

      {result && (
        <>
          <SectionPercentage>
            <PercentAssessment result={result} />
            <BoxInfosCalcul />
          </SectionPercentage>

          <CardsWrapper>
            {result.criterias?.map((criteria) => (
              <CardCriteria key={criteria.label} criteria={criteria} />
            ))}
          </CardsWrapper>
        </>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div``;
const SectionPercentage = styled.div`
  display: flex;
  > div:first-child {
    flex: 1;
    margin-right: 16px;
  }
`;
const TransportGroup = styled.div`
  margin-bottom: 16px;
  display: flex;
  div {
    margin-right: 12px;
  }
`;
const CardsWrapper = styled.div`
  margin-top: 32px;
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 16px;
`;
