import { format, parseISO } from 'date-fns';

export const formatDate = (date) => {
  const dateObj = new Date(date);
  const dateFormarred = format(dateObj, 'dd MMM yyyy');
  return dateFormarred;
};
export const formatTime = (date) => {
  const dateObj = parseISO(date);
  const timeFormatted = format(dateObj, 'h:mm a');
  return timeFormatted;
};
