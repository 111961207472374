import AccountBackground from '@features/account/components/AccountBackground';
import { ReactNode } from 'react';

type AccountProps = {
  card: ReactNode;
};

export default function Account({ card }: AccountProps) {
  return <AccountBackground>{card}</AccountBackground>;
}
