import { ReactComponent as InviteIcon } from '@assets/icons/profile-add.svg';
import { ReactComponent as DetailsIcon } from '@assets/icons/users-list.svg';
import { Box, Button, Heading, useDisclosure } from '@chakra-ui/react';
import CardVotes from '@features/assessment/assessment-organiser/CardVotes';
import CardCriteria from '@features/assessment/CardCriteria';
import { criteriasParticipantsChoice } from '@features/assessment/mock';
import { Criteria } from '@utils/types';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import ModalParticipants from './ModalParticipants';

export default function ParticipantsChoice() {
  const [criterias, setCriterias] = useState<Criteria[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    setCriterias(criteriasParticipantsChoice);
  }, []);

  return (
    <Wrapper>
      <Heading
        size="md"
        color="black"
        mb="12px"
        display="flex"
        alignItems="center"
        justifyContent="space-between">
        Choix des participants
        <Box>
          <Button
            onClick={onOpen}
            leftIcon={<DetailsIcon />}
            variant="tertiary"
            mr="12px">
            Detail
          </Button>
          <Button leftIcon={<InviteIcon />} variant="tertiary">
            Inviter
          </Button>
        </Box>
      </Heading>

      <CardVotes />

      <CardsWrapper>
        {criterias.length > 0 &&
          criterias.map((criteria: Criteria) => (
            <CardCriteria key={criteria.label} criteria={criteria} />
          ))}
      </CardsWrapper>

      <ModalParticipants isOpen={isOpen} onClose={onClose} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 48px;
`;

const CardsWrapper = styled.div`
  margin-top: 32px;
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 16px;
`;
